import type {
  ApiHandlerDataType,
  ClientHeaders,
  ClientMethod,
  HandlerOptions,
} from './client';
import { genericApiHandler } from './client';

const formatResponseByContentType = async (response: Response) => {
  const contentType = response.headers.get('Content-Type');

  if (
    contentType?.includes('image/png') ||
    contentType?.includes('application/pdf')
  ) {
    return await response.blob();
  }

  if (contentType?.includes('json')) {
    return (await response.json()) as unknown;
  }

  return undefined;
};

const formatBodyByBodyType = <Body>(body: Body) => {
  if (body instanceof FormData) {
    return body;
  }
  if (body instanceof File) {
    return body;
  }

  return JSON.stringify(body);
};

let abortController = new AbortController();

const createFetchClient = () => {
  const headers: ClientHeaders = {
    'Content-Type': 'application/json',
  };

  return {
    headers,
    fetch: async <
      Data,
      Params extends {} | undefined = {},
      Body extends {} | undefined = {}
    >(
      url: string,
      options?: {
        method?: ClientMethod;
        params?: Params;
        body?: Body;
        abortable?: boolean;
        includeCredentials?: boolean;
      }
    ) => {
      if (abortController) {
        abortController.abort();
      }

      abortController = new AbortController();
      const { signal } = abortController;

      const request: RequestInit = {
        method: options?.method ?? 'GET',
        headers,
        signal: options?.abortable ? signal : undefined,
      };

      if (options?.includeCredentials) {
        request.credentials = 'include';
      }

      if (options?.body) {
        request.body = formatBodyByBodyType(options.body);
      }

      const response = await fetch(url, request);

      let data: Data = {} as Data;

      try {
        data = (await formatResponseByContentType(response)) as Data;
      } catch (error) {
        if (response.status === 200) {
          data = {} as Data;
        } else {
          throw error;
        }
      }

      return {
        response: data,
        status: response.status,
        ok: response.statusText === 'OK',
      };
    },
  };
};

export const fetchClient = createFetchClient();

type FetchHandlerArgs<Params, Body> = {
  params?: Params;
  body?: Body;
  abortable?: boolean;
  contentType?: ClientHeaders['Content-Type'];
  includeCredentials?: boolean;
};

/**
 * fetchViaClient uses either `axios` or `fetch` based on the client provided
 * in the args.
 *
 * @param client "fetch" or "axios";
 * @param method One of the HTTP methods
 * @param  url endpoint url
 * @param options`AxiosHandlerArgs` or `FetchHandlerArgs`
 * @returns response from the api as a Promise
 */
const requestViaFetchClient = async <
  Data extends ApiHandlerDataType,
  Params extends {},
  Body extends {} | undefined = undefined
>({
  url,
  options,
  method,
}: {
  url: string;
  method: ClientMethod;
  options: FetchHandlerArgs<Params, Body>;
}) => {
  // If the content type is multipart/form-data, we need to remove the content type header
  // as fetch will automatically set the content type to multipart/form-data
  // Setting it manually to multipart/form-data will cause the request to fail
  // due to the boundary not being set correctly
  if (options.contentType === 'multipart/form-data') {
    delete fetchClient.headers['Content-Type'];
  } else {
    fetchClient.headers['Content-Type'] =
      options.contentType ?? 'application/json';
  }

  return await fetchClient.fetch<Data, Params, Body>(url, {
    method: method,
    body: options.body,
    params: options.params,
    abortable: options.abortable,
    includeCredentials: options.includeCredentials,
  });
};

export function apiFetchHandler<
  Data extends ApiHandlerDataType,
  Params extends {} = {},
  Body extends {} | undefined = undefined,
  TransformedData extends ApiHandlerDataType | undefined = undefined
>(options: HandlerOptions<Data, Params, Body, TransformedData>) {
  return genericApiHandler(options, requestViaFetchClient<Data, Params, Body>);
}
