import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'services/auth/AuthWrapper';
import type { ICheckPermissions } from 'services/auth/useAuthorisation';

type CommonArgs = {
  enabled?: boolean;
};

type SinglePermArgs = CommonArgs & {
  permissions: ICheckPermissions;
};

type MultiPermArgs = CommonArgs & {
  permissions: ICheckPermissions[];
};

type SinglePermReturn = {
  hasPermission: boolean;
  isFetched?: boolean;
};

type MultiPermReturn = {
  hasPermission: boolean[];
  isFetched?: boolean;
};

function useCheckPermissions(args: SinglePermArgs): SinglePermReturn;
function useCheckPermissions(args: MultiPermArgs): MultiPermReturn;
function useCheckPermissions({
  permissions,
  enabled,
}: SinglePermArgs | MultiPermArgs): SinglePermReturn | MultiPermReturn {
  const { checkPermissions } = useAuth();

  const { data, isFetched } = useQuery({
    queryKey: [permissions],
    queryFn: () =>
      checkPermissions(
        Array.isArray(permissions) ? permissions : [permissions]
      ),
    enabled,
  });

  if (!data) {
    return {
      hasPermission: Array.isArray(permissions)
        ? permissions.map(() => false)
        : false,
    };
  }

  return {
    hasPermission: Array.isArray(permissions) ? data : Boolean(data[0]),
    isFetched,
  };
}
export default useCheckPermissions;
