import { BACKEND_BASE_URL, FRONTEND_AUTHENTICATION_BASE_URL } from 'env';

export const hosts = {
  portal: {
    v0: BACKEND_BASE_URL + '/portal/v0',
    v1: BACKEND_BASE_URL + '/portal/v1',
    v2: BACKEND_BASE_URL + '/portal/v2',
    v3: BACKEND_BASE_URL + '/portal/v3',
  },
  data: {
    v0: BACKEND_BASE_URL + '/data/v0',
    v1: BACKEND_BASE_URL + '/data/v1',
  },

  storage: {
    v0: BACKEND_BASE_URL + '/data/v0/storage',
  },
  OSM: {
    search: 'https://nominatim.openstreetmap.org',
  },
  authentication: FRONTEND_AUTHENTICATION_BASE_URL,
};
