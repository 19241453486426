import { toNumber, find, pipe, get } from 'lodash/fp';
import { IconNames } from '@blueprintjs/icons';
import { useParams, useHistory } from 'react-router-dom';
import AdministrationHeader from 'pages/portal/shared/components/AdministrationHeader';
import AdministrationUsers from 'pages/portal/Administration/User/components/AdministrationUsers/AdministrationUsers';
import AdministrationLayout from '../../shared/components/AdministrationLayout/AdministrationLayout';
import useResource from 'pages/portal/shared/hooks/useResource';
import ResourcePermissionsManager from 'pages/portal/shared/components/PermissionManager/ResourcePermissionsManager';
import { usePortalService } from 'services/api/portal/organisation/usePortalService';
import { useAuth } from 'services/auth/AuthWrapper';

const ProgrammeAdministration = () => {
  const { programme } = useParams<{ programme: string }>();
  const { user } = useAuth();
  const { programmesList: programmes } = usePortalService(user ? user.sub : '');
  const history = useHistory();
  const programmeId = toNumber(programme);

  const {
    availableRoles,
    resourceRoleAssignments,
    assignRole,
    deleteRoleAssignment,
    resourceRoleAttachments,
    getRoleAttachmentsByResourceTypeAndId,
  } = useResource('programme', programmeId);

  const handleOrganisationLinkClick = () => {
    const prog = find(['id', programmeId], programmes);

    prog && history.push(`/portal/organisation/${prog.organisationId}`);
  };

  const programmeName = pipe(
    find(['id', programmeId]),
    get('name')
  )(programmes) as string;

  return (
    <AdministrationLayout
      breadcrumbs={[
        { text: 'Organisation', onClick: handleOrganisationLinkClick },
        { text: 'Programme' },
      ]}
      showEmptyData={!programmeName}
      emptyDataIcon={IconNames.CLEAN}
      emptyDataTitle={`There is no programme found with Id: ${programmeId}`}
      graphic="constellation"
    >
      <AdministrationHeader
        id={programmeId}
        title={programmeName}
        icon={IconNames.CLEAN}
      />
      <AdministrationUsers
        currentId={programmeId}
        availableRoles={availableRoles}
        roleAssignments={resourceRoleAssignments}
        assignRole={assignRole}
        deleteRoleAssignments={(
          userId: string,
          assignmentIds: number[],
          showDeletedMessage?: boolean
        ) => deleteRoleAssignment(assignmentIds, showDeletedMessage)}
      />
      <div className="my-3 border-t-[1px] border-accent-700 dark:border-item-dark-hover" />
      {resourceRoleAttachments && (
        <ResourcePermissionsManager
          resourceAttachments={resourceRoleAttachments}
          resourceType="programme"
          resourceId={programmeId.toString()}
          getAttachmentsCallback={() => getRoleAttachmentsByResourceTypeAndId()}
          availableRoles={availableRoles}
        />
      )}
    </AdministrationLayout>
  );
};

export default ProgrammeAdministration;
