import { Dialog } from '@blueprintjs/core';
import type { ProviderView } from 'datacosmos/stores/ViewsProvider';
import React, { useState } from 'react';
import s from 'datacosmos/components/DatacosmosViews/index.module.scss';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { LANGUAGE } from 'env';
import { Button } from 'opencosmos-ui';

type IProps = {
  view: ProviderView | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteHandler: (viewId: string) => void;
};

//TODO: Make the scenario delete dialog more generic and use it here in the meantime
const DeleteViewDialog = ({
  view,
  isOpen,
  setIsOpen,
  deleteHandler,
}: IProps) => {
  const { translate } = useLocalisation();
  const [deleteInput, setDeleteInput] = useState<string>('');
  const deleteText = LANGUAGE === 'en' ? 'delete' : 'eliminar';

  return (
    <Dialog
      isOpen={isOpen}
      canEscapeKeyClose
      onClose={() => setIsOpen(false)}
      hasBackdrop={false}
      className="dark:bg-surface-dark dark:text-item-dark-contrast bg-surface"
    >
      <div
        className={
          s.overlayContentWrapper +
          ' dark:bg-surface-dark dark:text-item-dark-contrast bg-surface flex flex-col gap-2'
        }
      >
        <div>
          <h1 className={s.overlayTitle}>
            {translate('datacosmos.deleteProjectDialog.warning')}
          </h1>
          <p>
            {translate('datacosmos.deleteViewDialog.areYouSure')} &quot;
            <b>{view?.name}</b>&quot;?
          </p>
        </div>

        <div className={s.overlayBody}>
          <p>{translate('datacosmos.deleteViewDialog.longWarning')}</p>
          <input
            type="text"
            style={{ width: '100%' }}
            placeholder={translate('datacosmos.deleteProjectDialog.warning')}
            onChange={(e) => setDeleteInput(e.target.value)}
            className="dark:bg-item-dark dark:text-item-dark-contrast h-8 border-2 border-item-contrast-text-field-stroke"
          />
        </div>

        <div className={s.overlayFooter}>
          <Button onPress={() => setIsOpen(false)}>
            {translate('datacosmos.buttons.cancel')}
          </Button>
          <Button
            intent="warning"
            onPress={() => {
              if (view) {
                deleteHandler(view.id);
                setIsOpen(false);
              }
            }}
            isDisabled={deleteInput.toLowerCase() !== deleteText}
          >
            {translate('datacosmos.buttons.confirm')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteViewDialog;
