import axios from 'axios';
import { showErrorMessage } from '../../../../utils/common/CommonUtils';
import { REQUEST_ERROR } from '../../../../constants/errors';
import { analyticsClient } from 'utils/hooks/analytics/useAnalytics';

export default (url: string, token: string | undefined) => {
  const postOrganisation = async (name: string) => {
    try {
      const {
        data: { data },
      } = (await axios.post(
        `${url}/organisations`,
        { name },
        {
          headers: {
            Authorization: `Bearer ${token ?? ''}`,
            'Content-Type': 'application/json',
          },
        }
      )) as { data: { data: { id: number } } };

      return data;
    } catch (e) {
      analyticsClient.sendError()({
        type: 'Organisation create error',
        action: 'Create error',
        item: 'Organisation creation',
        module: 'DataCosmos',
        error: {
          'Failure Type': 'Request error',
          error: REQUEST_ERROR,
          errorDetails: e,
        },
      });
      showErrorMessage(REQUEST_ERROR);
      return undefined;
    }
  };

  return { postOrganisation };
};
