import type { Placement } from 'react-joyride';
import { clientTranslate } from 'utils/hooks/useLocalisation';

export type ITourStep = {
  title: string;
  target: string;
  content: string;
  disableBeacon?: boolean;
  placement?: Placement | 'auto' | 'center';
};

export const TOUR_STEPS = [
  {
    title: clientTranslate('datacosmos.tours.mainAppTour.catalog'),
    target: '.tour-catalog',
    content: (
      <div
        dangerouslySetInnerHTML={{
          __html: clientTranslate(
            'datacosmos.tours.mainAppTour.catalogDescription'
          ),
        }}
        className="text-sm"
      ></div>
    ),
    disableBeacon: true, // Starts tour automatically without click
    placement: 'right' as Placement,
  },
  {
    title: clientTranslate('datacosmos.tours.mainAppTour.project'),
    target: '.tour-project',
    content: (
      <div
        dangerouslySetInnerHTML={{
          __html: clientTranslate(
            'datacosmos.tours.mainAppTour.projectDescription'
          ),
        }}
        className="text-sm"
      ></div>
    ),
    placement: 'right' as Placement,
  },
  {
    title: clientTranslate('datacosmos.tours.mainAppTour.applications'),
    target: '.tour-applications',
    content: (
      <div
        dangerouslySetInnerHTML={{
          __html: clientTranslate(
            'datacosmos.tours.mainAppTour.applicationsDescription'
          ),
        }}
        className="text-sm"
      ></div>
    ),

    placement: 'left' as Placement,
  },
  {
    title: clientTranslate('datacosmos.tours.mainAppTour.layers'),
    target: '.tour-layers',
    content: (
      <div
        dangerouslySetInnerHTML={{
          __html: clientTranslate(
            'datacosmos.tours.mainAppTour.layersDescription'
          ),
        }}
        className="text-sm"
      ></div>
    ),
    placement: 'left' as Placement,
  },
];
