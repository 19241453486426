import classNames from 'classnames';
import type { DetailedHTMLProps } from 'react';
import { Tooltip, Icon } from 'opencosmos-ui';

type RadioInput = {
  name: string;
  value: string | number;
  disabled: boolean;
  description?: string;
};

export type InputProps = {
  label?: {
    text: string;
    position: 'top' | 'left' | 'right';
  };
  className?: string;
  errorMsg?: string;
  radioItems: RadioInput[];
  radioName: string;
} & DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const RadioInput = (props: InputProps) => {
  const renderDescriptionTooltip = (radioInfo: RadioInput) => {
    if (radioInfo.description)
      return (
        <Tooltip content={radioInfo.description}>
          <Icon icon="Info" className="align-middle z-auto" />
        </Tooltip>
      );
    return null;
  };
  return (
    <div className="flex-col gap-2">
      <div
        className={classNames({
          'flex !shadow-none grid-cols-2 gap-4 items-center':
            props.label &&
            (props.label.position === 'left' ||
              props.label.position === 'right'),
          'flex-col gap-1': props.label && props.label.position === 'top',
        })}
      >
        {props.label && (
          <label
            htmlFor="custom-input"
            className={classNames('font-bold text-md whitespace-nowrap', {
              'order-1': props.label.position === 'left',
              'order-2': props.label.position === 'right',
            })}
          >
            {props.label.text}
          </label>
        )}

        {props.radioItems?.map((radioInfo: RadioInput) => (
          <div key={radioInfo.value} className={props.className}>
            <input
              type="radio"
              value={radioInfo.value}
              name={props.radioName}
              id={radioInfo.value.toString()}
              onChange={props.onChange}
              disabled={radioInfo.disabled}
            ></input>
            <label
              htmlFor={radioInfo.value.toString()}
              className="flex items-center gap-2"
            >
              {radioInfo.name} {renderDescriptionTooltip(radioInfo)}
            </label>
          </div>
        ))}
      </div>
      {props.errorMsg && (
        <small className="text-warning">{props.errorMsg}</small>
      )}
    </div>
  );
};

export default RadioInput;
