import {
  downloadGeojsonAsKml,
  downloadGeojsonAsShp,
  downloadGeojsonAsKmz,
  downloadAOIasGeojson,
} from 'datacosmos/download/geojson';
import type { CircleLayer } from 'datacosmos/entities/circleLayer';
import type { LineLayer } from 'datacosmos/entities/lineLayer';
import type { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { geoJsonFeatureToGeoJsonFeatureCollection } from 'datacosmos/utils/geojson';

export type DownloadType = {
  ext: 'geojson' | 'kml' | 'shp' | 'kmz';
  handler: () => void;
};

export const getDownloadTypes = (
  l: LineLayer | PolygonLayer | CircleLayer
): DownloadType[] => [
  { ext: 'geojson', handler: () => downloadAOIasGeojson(l.data, l.name) },
  { ext: 'kml', handler: () => downloadGeojsonAsKml(l.data, l.name) },
  {
    ext: 'shp',
    handler: () =>
      downloadGeojsonAsShp(
        geoJsonFeatureToGeoJsonFeatureCollection(l.data as GeoJSON.Feature),
        l.name
      ),
  },
  { ext: 'kmz', handler: () => downloadGeojsonAsKmz(l.data, l.name) },
];
