import { apiFetchHandler } from '_api/clientFetch';
import { hosts } from '_api/hosts';
import { clientTranslate } from 'utils/hooks/useLocalisation';

export type GetFileParams = {
  path: string;
};

export const getStorageFile = apiFetchHandler<Blob, GetFileParams>({
  host: hosts.storage.v0,
  endpoint: ({ path }) => path,
  method: 'GET',
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.storage.getRequestError'
  ),
});

export type PutFileBody = Blob;

export type PutFileParams = {
  path: string;
};

export const putStorageFile = apiFetchHandler<null, PutFileParams, PutFileBody>(
  {
    host: hosts.storage.v0,
    endpoint: ({ path }) => path,
    method: 'PUT',
    errorMessage: clientTranslate(
      'datacosmos.fetchErrors.storage.putRequestError'
    ),
  }
);
