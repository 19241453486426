import type { CondataSatellite } from '_api/satellites/types';

// Intended for condata satellites
export const CONDATA_SATELLITES: CondataSatellite[] = [
  {
    description: '',
    uiName: 'Terra',
    gsdData: 90.0,
    COSPAR: '1999-068A',
  },
  {
    description: '',
    uiName: 'Cbers-2b',
    gsdData: 20.0,
    COSPAR: '2007-042A',
  },
  {
    description: '',
    uiName: 'Cbers-4a',
    gsdData: 8.0,
    COSPAR: '2019-093E',
  },
  {
    description: '',
    uiName: 'Cosmo-skymed-4',
    gsdData: 2.5,
    COSPAR: '2010-060A',
  },
  {
    description: '',
    uiName: 'Kazeosat-1',
    gsdData: 4,
    COSPAR: '2014-024A',
  },
  {
    description: '',
    uiName: 'Kompsat-3',
    gsdData: 3.279,
    COSPAR: '2012-025B',
  },
  {
    description: '',
    uiName: 'Landsat-8',
    gsdData: 30.0,
    COSPAR: '2013-008A',
  },
  {
    description: '',
    uiName: 'Noaa14',
    gsdData: 1050.0,
    COSPAR: '1994-089A',
  },
  {
    description: '',
    uiName: 'Noaa16',
    gsdData: 1050.0,
    COSPAR: '2000-055A',
  },
  {
    description: '',
    uiName: 'Noaa19',
    gsdData: 1050.0,
    COSPAR: '2009-005A',
  },
  {
    description: '',
    uiName: 'Perusat-1',
    gsdData: 3.6,
    COSPAR: '2016-058A',
  },
  {
    description: '',
    uiName: 'Pleiades-1a',
    gsdData: 0.7875692556046401,
    COSPAR: '2011-076F',
  },
  {
    description: '',
    uiName: 'Pleiades-1b',
    gsdData: 0.7086981314065767,
    COSPAR: '2012-068A',
  },
  {
    description: '',
    uiName: 'Sentinel-2a',
    gsdData: 60.0,
    COSPAR: '2015-028A',
  },
  {
    description: '',
    uiName: 'Sentinel-2b',
    gsdData: 10.0,
    COSPAR: '2017-013A',
  },
  {
    description: '',
    uiName: 'Spot-6',
    gsdData: 10.0278436384,
    COSPAR: '2012-047A',
  },
  {
    description: '',
    uiName: 'Spot-7',
    gsdData: 11.8892692542,
    COSPAR: '2014-034A',
  },
  {
    description: '',
    uiName: 'Terrasar-x',
    gsdData: 8.25,
    COSPAR: '2007-026A',
  },
];
