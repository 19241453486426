import type { StacItem } from 'datacosmos/types/stac-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ConfirmPurchaseImageCarousel from 'datacosmos/components/ConfirmPurchase/ConfirmPurchaseImageCarousel';
import ConfirmPurchaseItemList from 'datacosmos/components/ConfirmPurchase/ConfirmPurchaseItemList';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { getImagePrice } from '_api/orders/service';
import type { Order, PaymentMethod, Price } from '_api/orders/types';
import {
  DATACOSMOS_CHECKOUT_CARD,
  DATACOSMOS_CHECKOUT_MANUAL_BANK_TRANSFER,
  DATACOSMOS_CHECKOUT_MANUAL_BANK_TRANSFER_TEXT,
  INCLUDE_CHECKBOX_FOR_PURCHASE,
} from 'env';
import { Text } from 'ui/Text';
import { toaster } from 'toaster';
import { formatPrice, isDevMode } from 'utils/common/CommonUtils';
import { Checkbox, Dialog, Icon } from 'opencosmos-ui';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import classNames from 'classnames';
import Input from 'opencosmos-ui/src/core/Input/Input';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';

interface IProps {
  isOpen: boolean;
  imagesToPurchase: StacItem[];
  setIsOpen: (open: boolean) => void;
  createOrderWithItemsInCart: (
    paymentMethod: PaymentMethod
  ) => Promise<Order> | undefined;
  setExternalPaymentId: React.Dispatch<React.SetStateAction<string>>;
  externalPaymentId: string;
}

const ConfirmPurchaseDialog = ({
  isOpen,
  imagesToPurchase,
  setIsOpen,
  createOrderWithItemsInCart,
  setExternalPaymentId,
  externalPaymentId,
}: IProps) => {
  const [selectedImage, setSelectedImage] = useState<StacItem>();
  const [totalPrice, setTotalPrice] = useState<Price | undefined>();
  const [isFinalPriceNonZero, setIsFinalPriceNonZero] =
    useState<boolean>(false);

  const [isByBankOpen, setIsByBankOpen] = useState<boolean>(false);

  const [isPriceFetching, setIsPriceFetching] = useState<boolean>(false);

  const [isOrderCreating, setIsOrderCreating] = useState<boolean>(false);

  const [paymentReferenceId, setPaymentReferenceId] = useState<string>('');

  const [itemsWithPrice, setItemsWithPrice] = useState<
    { item: StacItem; price: Price }[]
  >([]);

  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);

  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  const themeProvider = useTheme();

  const urlErrorMessage: { error: string; message: string } | undefined =
    useMemo(() => {
      const msgString = urlParams.get('payment_message');

      if (!msgString) {
        return undefined;
      }

      return JSON.parse(msgString) as { error: string; message: string };
    }, [urlParams]);

  const [isByCardOpen, setIsByCardOpen] = useState<boolean>(
    Boolean(urlParams.get('payment_status'))
  );

  const { translate } = useLocalisation();

  const fetchPrices = useCallback(async () => {
    if (!imagesToPurchase || imagesToPurchase.length === 0) {
      return;
    }

    setIsPriceFetching(true);
    const pricesPromises = imagesToPurchase.map(async (img) => {
      const { data: price } = await getImagePrice({
        params: {
          collection: img.collection ?? '',
          itemId: img.id,
        },
      });

      return price ?? ({} as Price);
    });

    const prices = await Promise.all(pricesPromises);

    const withPrice = imagesToPurchase.map((img, index) => ({
      item: img,
      price: prices[index],
    }));

    setItemsWithPrice(withPrice);

    const cumulativePrice = prices.reduce(
      (acc, curr) => {
        if (acc.final === '') {
          acc = curr;
        } else {
          acc = {
            ...acc,
            final: (Number(acc.final) + Number(curr.final))
              .toFixed(2)
              .toString(),
            discount: (Number(acc.discount) + Number(curr.discount))
              .toFixed(2)
              .toString(),
            value: (Number(acc.value) + Number(curr.value))
              .toFixed(2)
              .toString(),
          };
        }

        return acc;
      },
      {
        value: '',
        currency: '',
        discount: '',
        final: '',
      } as Price
    );

    const formattedCumulativePrice = {
      ...cumulativePrice,
      final: formatPrice(cumulativePrice.final),
      discount: formatPrice(cumulativePrice.discount),
      value: formatPrice(cumulativePrice.value),
    };

    setTotalPrice(formattedCumulativePrice);
    setIsFinalPriceNonZero(Number(cumulativePrice?.final) > 0);
    setIsPriceFetching(false);
  }, [imagesToPurchase]);

  useEffect(() => {
    // ensures API calls for price are not triggered before the popup is opened
    if (!isOpen) {
      return;
    }
    void fetchPrices();
  }, [fetchPrices, isOpen]);

  useEffect(() => {
    setSelectedImage(imagesToPurchase[0]);
  }, [imagesToPurchase]);

  const payButtonText = isFinalPriceNonZero
    ? translate('datacosmos.buttons.purchase')
    : translate('datacosmos.buttons.completeOrder');

  const createOrderForBankTransfer = async () => {
    setIsOrderCreating(true);
    const order = await createOrderWithItemsInCart('BANK_TRANSFER');

    if (!order) {
      setIsOrderCreating(false);
      setIsOpen(false);
      return;
    }
    setPaymentReferenceId(order.payment_reference_number ?? 'XXXXXXX');
    setIsOrderCreating(false);
  };

  const { hasPermission: isPublicUser } = useCheckPermissions({
    permissions: {
      type: 'global',
      actionScope: 'user-is-public',
    },
    enabled: INCLUDE_CHECKBOX_FOR_PURCHASE,
  });

  return (
    <>
      <Dialog
        title={translate(
          'datacosmos.catalogAndItems.purchaseDialog.orderCheckout'
        )}
        isOpen={isOpen}
        showButtonsInFooter
        buttons={[
          {
            onPress: async () => {
              setIsOrderCreating(true);
              await createOrderWithItemsInCart('NOT_REQUIRED');
              setIsOrderCreating(false);
            },
            text: payButtonText,
            shown: Boolean(
              totalPrice && parseInt(totalPrice.final.replace(/,/g, '')) === 0
            ),
            showLoadingIndicator: isOrderCreating,
            isDisabled: INCLUDE_CHECKBOX_FOR_PURCHASE && !isTermsChecked,
          },
          {
            onPress: async () => {
              setIsOrderCreating(true);
              const order = await createOrderWithItemsInCart('CARD');

              if (!order) {
                setIsOrderCreating(false);
                setIsOpen(false);
                return;
              }

              const newURL = order?.checkout_redirect_url;

              if (typeof newURL !== 'undefined') {
                window.location.href = newURL;
              }

              if (!newURL?.length) {
                toaster.show({
                  icon: 'error',
                  intent: 'danger',
                  message: 'Could not proceed to checkout.',
                });
              }

              setIsOrderCreating(false);
            },
            text: translate('datacosmos.buttons.payByCard'),
            shown: DATACOSMOS_CHECKOUT_CARD && isFinalPriceNonZero,
            showLoadingIndicator: isOrderCreating,
            isDisabled: INCLUDE_CHECKBOX_FOR_PURCHASE && !isTermsChecked,
          },
          {
            onPress: () => {
              setIsByBankOpen(true);
            },
            text: translate('datacosmos.buttons.payByBank'),
            shown:
              DATACOSMOS_CHECKOUT_MANUAL_BANK_TRANSFER && isFinalPriceNonZero,
            showLoadingIndicator: isOrderCreating,
            isDisabled: INCLUDE_CHECKBOX_FOR_PURCHASE && !isTermsChecked,
          },
          {
            onPress: async () => {
              setIsOrderCreating(true);
              await createOrderWithItemsInCart('NONE');
              setIsOrderCreating(false);
            },
            text: payButtonText,
            shown:
              !DATACOSMOS_CHECKOUT_MANUAL_BANK_TRANSFER &&
              !isDevMode() &&
              isFinalPriceNonZero,
            showLoadingIndicator: isOrderCreating,
            isDisabled: INCLUDE_CHECKBOX_FOR_PURCHASE && !isTermsChecked,
          },
        ]}
        onClose={() => {
          setIsOpen(!isOpen);
          setIsTermsChecked(false);
        }}
        cancelButtonText={translate('datacosmos.buttons.close')}
      >
        <div className="flex flex-col gap-4">
          <div
            data-testid="confirm-purchase-dialog"
            className={classNames('flex gap-4', {
              'border-b-2 pb-2': INCLUDE_CHECKBOX_FOR_PURCHASE,
            })}
          >
            <ConfirmPurchaseImageCarousel
              currentImage={selectedImage}
              imagesToPurchase={imagesToPurchase}
              setCurrentImage={setSelectedImage}
            />
            <ConfirmPurchaseItemList
              currentImage={selectedImage}
              imagesToPurchase={imagesToPurchase}
              setCurrentImage={setSelectedImage}
              totalPrice={totalPrice}
              isFetchingPrice={isPriceFetching}
              itemsWithPrice={itemsWithPrice}
            />
          </div>
          {INCLUDE_CHECKBOX_FOR_PURCHASE && (
            <div className="text-xs px-3">
              <Checkbox
                themeProvider={themeProvider}
                isDisabled={!totalPrice}
                onChange={(selected) => {
                  setIsTermsChecked(Boolean(selected));
                }}
                isSelected={isTermsChecked}
              >
                <div
                  dangerouslySetInnerHTML={{
                 __html: isPublicUser
                    ? `${translate(
                        'datacosmos.catalogAndItems.purchaseDialog.publicTermsAndConditionsText'
                      )}`
                    : `${translate(
                        'datacosmos.catalogAndItems.purchaseDialog.privateTermsAndConditionsText'
                      )}`,
                  }}
                ></div>
              </Checkbox>
            </div>
          )}
        </div>
      </Dialog>

      <Dialog
        title={translate(
          'datacosmos.catalogAndItems.purchaseDialog.orderCheckout'
        )}
        isOpen={isByBankOpen}
        onClose={() => {
          setIsByBankOpen(false);
          setExternalPaymentId('');
          setPaymentReferenceId('');
        }}
        buttons={[
          {
            text: translate('datacosmos.buttons.accept'),
            shown: paymentReferenceId?.length > 0,
            onPress: () => {
              setIsByBankOpen(false);
              setExternalPaymentId('');
              setPaymentReferenceId('');
            },
          },

          {
            text: translate('datacosmos.buttons.continueToPayment'),
            shown: paymentReferenceId?.length === 0,
            onPress: async () => {
              await createOrderForBankTransfer();
            },
            keepDialogOpenOnPress: true,
            showLoadingIndicator: isOrderCreating,
            isDisabled: externalPaymentId?.length === 0,
          },
        ]}
        hideCancelButton={true}
      >
        <div className="flex flex-col gap-2 h-80">
          <Text bold>
            {translate(
              'datacosmos.catalogAndItems.purchaseDialog.payingViaBankTransfer'
            )}
          </Text>

          {paymentReferenceId?.length ? (
            <>
              <Text>
                {translate(
                  'datacosmos.catalogAndItems.purchaseDialog.pleaseMakeATransfer'
                )}
              </Text>

              <Text>{DATACOSMOS_CHECKOUT_MANUAL_BANK_TRANSFER_TEXT}</Text>

              <Text>
                {translate(
                  'datacosmos.catalogAndItems.purchaseDialog.pleaseMakeATransferWithRef',
                  { reference: paymentReferenceId }
                )}
              </Text>

              <Text breakLine>
                {translate(
                  'datacosmos.catalogAndItems.purchaseDialog.dataPurchasedWillNotBeAvailableUntil'
                )}
              </Text>

              <Text>
                {translate(
                  'datacosmos.catalogAndItems.purchaseDialog.verificationOfPaymentDetailsCanTake'
                )}
              </Text>
            </>
          ) : (
            <div className="px-2">
              <Input
                type="text"
                label={{
                  position: 'top',
                  text: translate(
                    'datacosmos.catalogAndItems.purchaseDialog.userIdLabel'
                  ),
                }}
                value={externalPaymentId}
                onChange={(e) => setExternalPaymentId(e.target.value)}
              />

              <div className="flex items-center gap-2 bg-accent-200 dark:bg-accent-dark my-4 p-1 text-sm dark:text-item-contrast">
                <Icon icon="Info" />
                <span>
                  {translate(
                    'datacosmos.catalogAndItems.purchaseDialog.userIdDescription'
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
      </Dialog>

      <Dialog
        buttons={[]}
        onClose={() => {
          setIsByCardOpen(false);
        }}
        title={translate(
          'datacosmos.catalogAndItems.purchaseDialog.orderCheckout'
        )}
        isOpen={isByCardOpen}
        cancelButtonText={translate('datacosmos.buttons.close')}
      >
        {urlParams.get('payment_status') === 'error' ? (
          <div className="flex flex-col">
            <Text className="dark:text-white">{urlErrorMessage?.error}</Text>
            <Text className="dark:text-white" breakLine>
              {urlErrorMessage?.message}
            </Text>
          </div>
        ) : (
          <></>
        )}
        {urlParams.get('payment_status') === 'success' ? (
          <Text className="dark:text-white">
            {translate(
              'datacosmos.catalogAndItems.purchaseDialog.paymentViaCardComplete'
            )}
          </Text>
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
};

export default ConfirmPurchaseDialog;
