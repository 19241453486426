import React from 'react';
import { Home } from 'datacosmos/components/Home';
import {
  ImageCatalogProvider,
  useImageCatalog,
} from 'datacosmos/stores/ImageCatalogProvider';
import { ApplicationCatalogProvider } from 'datacosmos/stores/ApplicationProvider';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import { MapProvider, useMap } from 'datacosmos/stores/MapProvider';
import {
  MapLayersProvider,
  useMapLayers,
} from 'datacosmos/stores/MapLayersProvider';
import { MapControllerProvider } from 'datacosmos/stores/MapControllerProvider';
import FiltersProvider from 'datacosmos/stores/FiltersProvider';
import {
  ProjectProvider,
  useProjects,
} from 'datacosmos/stores/ProjectProvider';
import { DatacosmosHeader } from 'datacosmos/components/Header/DatacosmosHeader';
import { ViewsProvider } from 'datacosmos/stores/ViewsProvider';
import ActivePageProvider from 'datacosmos/components/Toolbar/ActivePageProvider';
import BandAlgebraProvider from 'datacosmos/stores/BandAlgebraProvider';
import ThemeProvider from 'datacosmos/stores/ThemeProvider';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import TimeSeriesProvider from './stores/TimeSeriesProvider';
import { PixelModeProvider } from './stores/PixelModeProvider';
import CartProvider from './stores/CartProvider';
import { TourProvider } from './stores/TourProvider';
import { I18nProvider } from 'react-aria-components';
import { LANGUAGE } from 'env';
import { LayerToolsProvider } from './stores/LayerToolsProvider';
import { useLocation } from 'react-router-dom';
import { FullPageMap } from './components/FullPageMap';

export const HomeController = ({ children }: { children: React.ReactNode }) => {
  return (
    <MapControllerProvider map={useMap()}>{children}</MapControllerProvider>
  );
};

export const MapController = ({ children }: { children: React.ReactNode }) => {
  return (
    <MapProvider
      catalog={useImageCatalog()}
      layers={useMapLayers()}
      projects={useProjects()}
      applicationCatalog={useApplicationCatalog()}
    >
      {children}
    </MapProvider>
  );
};

const locale = LANGUAGE === 'en' ? 'en-GB' : 'es-ES';

export const HomeWrapper = () => {
  const location = useLocation();
  const isRenderMapWithBBox =
    location.pathname === '/data/map' &&
    new URLSearchParams(location.search).has('bbox');

  return (
    <ThemeProvider>
      <TourProvider>
        <FiltersProvider>
          <CartProvider>
            <ProjectProvider>
              <ImageCatalogProvider>
                <ApplicationCatalogProvider>
                  <MapLayersProvider>
                    <TimeSeriesProvider>
                      <MapController>
                        <ViewsProvider>
                          <HomeController>
                            <PixelModeProvider>
                              <LayerToolsProvider>
                                {!isRenderMapWithBBox && <DatacosmosHeader />}
                                <ActivePageProvider>
                                  <BandAlgebraProvider>
                                    <I18nProvider locale={locale}>
                                      {isRenderMapWithBBox ? (
                                        <div
                                          className={
                                            'openapp-data openapp-data-root'
                                          }
                                          style={{ overflow: 'hidden' }}
                                        >
                                          <FullPageMap />
                                        </div>
                                      ) : (
                                        <Home />
                                      )}
                                    </I18nProvider>
                                  </BandAlgebraProvider>
                                </ActivePageProvider>
                              </LayerToolsProvider>
                            </PixelModeProvider>
                          </HomeController>
                        </ViewsProvider>
                      </MapController>
                    </TimeSeriesProvider>
                  </MapLayersProvider>
                </ApplicationCatalogProvider>
              </ImageCatalogProvider>
            </ProjectProvider>
          </CartProvider>
        </FiltersProvider>
      </TourProvider>
    </ThemeProvider>
  );
};
