import NotFoundPage from 'pages/shared/NotFoundPage';
import { LoginPage } from 'pages/shared/LoginPage';
import LogoutPage from 'pages/shared/LogoutPage';
import BasePage from 'pages/shared/BasePage';
import OrganisationAdministration from 'pages/portal/Administration/Organisation/OrganisationAdministration';
import ProgrammeAdministration from 'pages/portal/Administration/Programme/ProgrammeAdministration';
import RolesAdministration from 'pages/portal/Administration/Roles/RolesAdministration';
import UserAdministration from 'pages/portal/Administration/UserAdministration';
import { HomeWrapper } from 'datacosmos';
import UserPage from 'pages/portal/Administration/User/UserPage';
import { ViewsScreen } from 'pages/datacosmos/views/ViewsScreen';
import OrdersScreen from 'pages/datacosmos/orders/OrdersScreen';
import AuthErrorPage from 'pages/shared/AuthErrorPage';
import GenericResourcePage from 'pages/datacosmos/GenericResourcePage';
import type {
  PermissionScope,
  PermissionType,
} from '_api/administration/permissions';
import type { ComponentType } from 'react';
import OrganisationsList from 'pages/portal/Administration/Organisation/OrganisationsList';
import { MapWrapper } from 'datacosmos/MapWrapper';

export type RouteType = {
  path?: string;
  auth: PermissionScope[];
  permissionType?: PermissionType;
  isPrivate?: boolean;
  exact?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: ComponentType<any>;
};

// Define app routes here.
// Each route should have a path, a component, and an array of auth strings.
// The auth strings are used to check if the user has the required permissions to access the route.
// If the route is set to private, user must have proper permissions
export const routes: RouteType[] = [
  {
    path: '/login',
    auth: [],
    exact: true,
    component: LoginPage,
  },
  {
    path: '/',
    auth: [],
    exact: true,
    component: BasePage,
  },
  {
    path: '/loggedin',
    auth: [],
    exact: true,
    component: BasePage,
  },
  {
    path: '/loggedout',
    auth: [],
    exact: true,
    component: LogoutPage,
  },
  {
    path: '/error',
    auth: [],
    exact: true,
    component: AuthErrorPage,
  },

  {
    path: '/portal/organisations',
    auth: ['organisation:assignment:read'],
    permissionType: 'organisation',
    exact: true,
    component: OrganisationsList,
    isPrivate: true,
  },
  {
    path: '/portal/organisation/:organisation',
    auth: ['organisation:assignment:read'],
    permissionType: 'organisation',
    exact: false,
    component: OrganisationAdministration,
    isPrivate: true,
  },
  {
    path: '/portal/programme/:programme',
    auth: ['programme:assignment:read'],
    permissionType: 'programme',
    exact: false,
    component: ProgrammeAdministration,
    isPrivate: true,
  },
  {
    path: '/portal/roles',
    auth: ['role:read'],
    exact: false,
    component: RolesAdministration,
    isPrivate: true,
  },
  {
    path: '/portal/users',
    auth: ['user:assignment:write'],
    exact: true,
    component: UserAdministration,
    isPrivate: true,
  },
  {
    path: '/portal/users/:user_subject',
    auth: ['role:read'],
    component: UserPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/data/map',
    auth: [],
    exact: false,
    component: MapWrapper,
  },

  {
    path: '/data/project',
    auth: ['data:scenario:read:own'],
    exact: false,
    component: HomeWrapper,
    isPrivate: true,
  },
  {
    path: '/data/views',
    auth: ['data:view:read:own'],
    exact: false,
    component: ViewsScreen,
    permissionType: 'datacosmos_view',
    isPrivate: true,
  },

  {
    path: '/data/orders',
    auth: [],
    exact: false,
    component: OrdersScreen,
    isPrivate: true,
  },
  {
    path: '/resource/:resource_type/:resource_id',
    auth: [],
    exact: false,
    component: GenericResourcePage,
    isPrivate: true,
  },
  {
    auth: [],
    component: NotFoundPage,
  },
];
