import React from 'react';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { I18nProvider } from 'react-aria-components';
import { FullPageMap } from './components/FullPageMap';

const locale = 'es-ES';

export const MapWrapper = () => {
  return (
    <I18nProvider locale={locale}>
      <div
        className={'openapp-data openapp-data-root'}
        style={{ overflow: 'hidden' }}
      >
        <FullPageMap />
      </div>
    </I18nProvider>
  );
};
