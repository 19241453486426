import React, { useState, useEffect, useCallback, useMemo } from 'react';
import applicationApi from 'datacosmos/services/applicationApi';
import { useAuth } from 'services/auth/AuthWrapper';
import { useRouteMatch } from 'react-router';
import { routeApplicationStatus } from 'datacosmos/components/routePath';
import type {
  IAPIAppValue,
  IApplication,
  IApplicationRunStatus,
  IApplicationStatus,
} from 'datacosmos/types/applications';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { ApplicationCatalogContext } from 'datacosmos/stores/ApplicationCatalogContext';
import { cropImageApp } from 'datacosmos/components/Applications/CropImage/CropImage';
import { TOAApp } from 'datacosmos/components/Applications/CondataApps/TOA';
import { Perusat1IngestionApp } from 'datacosmos/components/Applications/CondataApps/Perusat1Ingestion';
import { ChangeDetectionApp } from 'datacosmos/components/Applications/CondataApps/ChangeDetection';
import { SPOTIngestionApp } from 'datacosmos/components/Applications/CondataApps/SPOTIngestion';
import { TerraSATXIngestionApp } from 'datacosmos/components/Applications/CondataApps/TerraSATXIngestion';
import { CosmoSkymedIngestionApp } from 'datacosmos/components/Applications/CondataApps/COSMOskymeedIngestion';
import { PLEIADESIngestionApp } from 'datacosmos/components/Applications/CondataApps/PLEIADESIngestion';
import { KompSat3IngestionApp } from 'datacosmos/components/Applications/CondataApps/Kompsat3Ingestion';
import { KazEOSATIngestionApp } from 'datacosmos/components/Applications/CondataApps/KazEOSATIngestion';
import { SupervisedClassificationApp } from 'datacosmos/components/Applications/CondataApps/SupervisedClassification';
import { OrthorectificationApp } from 'datacosmos/components/Applications/CondataApps/Orthorectification';
import { CoregistrationApp } from 'datacosmos/components/Applications/CondataApps/Coregistration';
import { UnsupervisedClassificationApp } from 'datacosmos/components/Applications/CondataApps/UnsupervisedClassification';
import { BOAApp } from 'datacosmos/components/Applications/CondataApps/BOA';
import { LandsatIngestionApp } from 'datacosmos/components/Applications/CondataApps/LandsatIngestion';
import { SentinelIngestionApp } from 'datacosmos/components/Applications/CondataApps/SentinelIngestion';
import { CbersIngestionApp } from 'datacosmos/components/Applications/CondataApps/CbersIngestion';
import { AsterIngestionApp } from 'datacosmos/components/Applications/CondataApps/AsterIngestion';
import { ModisIngestionApp } from 'datacosmos/components/Applications/CondataApps/ModisIngestion';
import { MissingPixelsApp } from 'datacosmos/components/Applications/CondataApps/MissingPixels';
import { NOAAIngestionApp } from 'datacosmos/components/Applications/CondataApps/NOAAIngestion';
import { useProjects } from './ProjectProvider';
import { Sentinel1IngestionApp } from 'datacosmos/components/Applications/CondataApps/Sentinel1Ingestion';
import { GAOFENIngestionApp } from 'datacosmos/components/Applications/CondataApps/GAOFENIngestion';
import { SAOCOMIngestionApp } from 'datacosmos/components/Applications/CondataApps/SAOCOMIngestion';
import { ALOSIngestionApp } from 'datacosmos/components/Applications/CondataApps/ALOSIngestion';
import { VectorIngestionApp } from 'datacosmos/components/Applications/CondataApps/VectorIngestion';
import { PlanetIngestionApp } from 'datacosmos/components/Applications/CondataApps/PlanetIngestion';
import { DEMGenerationApp } from 'datacosmos/components/Applications/CondataApps/DEMGeneration';

const useApplicationCatalogProvider = () => {
  // These are the apps that are available on the Condata platform
  const ConidaApps = useMemo(
    () => [
      cropImageApp,
      TOAApp,
      BOAApp,
      ChangeDetectionApp,
      Perusat1IngestionApp,
      SPOTIngestionApp,
      TerraSATXIngestionApp,
      PLEIADESIngestionApp,
      CosmoSkymedIngestionApp,
      KompSat3IngestionApp,
      KazEOSATIngestionApp,
      LandsatIngestionApp,
      SentinelIngestionApp,
      CbersIngestionApp,
      AsterIngestionApp,
      ModisIngestionApp,
      NOAAIngestionApp,
      SupervisedClassificationApp,
      OrthorectificationApp,
      CoregistrationApp,
      UnsupervisedClassificationApp,
      MissingPixelsApp,
      Sentinel1IngestionApp,
      GAOFENIngestionApp,
      SAOCOMIngestionApp,
      ALOSIngestionApp,
      VectorIngestionApp,
      PlanetIngestionApp,
      DEMGenerationApp,
    ],
    []
  );

  /**
   * Front end applications
   * Register in this array the applications that run on the front-end.
   * This will be the initial apps on the apps, the backend apps will be appended
   * after we query the backend.
   */

  const FrontEndApps = useMemo(() => ConidaApps, [ConidaApps]);

  const { authBackend, token } = useAuth();

  const { currentScenario } = useProjects();

  const applicationStatusRouteMatch = useRouteMatch<string>(
    routeApplicationStatus
  );
  const applicationStatus = applicationStatusRouteMatch?.params;

  const [applicationList, setApplicationList] = useState<IApplication[]>([]);

  const [applicationStatusResult, setApplicationStatusResult] =
    useState<IApplicationStatus[]>();

  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const [subscribeApplicationName, setSubscribeApplicationName] = useState('');

  const [applicationAOIs, setApplicationAOIs] = useState<
    GeoJSON.Polygon[] | undefined
  >(undefined);

  const [installedApps, setInstalledApps] = useLocalStorage<IApplication[]>(
    'installedApps',
    []
  );

  const [selectedInstalledApp, setSelectedInstalledApp] =
    useState<IApplication>();

  const [shouldAutoOpen, setShouldAutoOpen] = useState<boolean>(false);

  const [isApplicationRunsOpen, setIsApplicationRunsOpen] =
    useState<boolean>(false);

  const openSubscribeModal = useCallback((applicationName: string) => {
    setIsSubscribeModalOpen(true);
    setSubscribeApplicationName(applicationName);
  }, []);

  const closeSubscribeModal = useCallback(() => {
    setIsSubscribeModalOpen(false);
    setSubscribeApplicationName('');
  }, []);

  const setInputData = useCallback(
    (applicationToSet: string, values: { [key: string]: IAPIAppValue }) => {
      setApplicationList((prev) =>
        prev.map((app) => {
          if (app.name === applicationToSet) {
            app.values = { ...app.values, ...values };
          }
          return app;
        })
      );
    },
    []
  );

  const queryApplicationStatus = useCallback(async () => {
    const { fetchApplicationStatus } = applicationApi(token);
    setApplicationStatusResult(
      (await fetchApplicationStatus()) as IApplicationStatus[]
    );
  }, [token]);

  const getApplicationRunStatus = useCallback(async () => {
    if (!currentScenario) {
      return;
    }
    const { fetchApplicationRunStatus } = applicationApi(token);
    return (await fetchApplicationRunStatus(
      currentScenario?.id
    )) as IApplicationRunStatus[];
  }, [token, currentScenario]);

  useEffect(() => {
    if (authBackend === undefined) {
      return;
    }
    if (
      applicationStatus &&
      applicationStatusRouteMatch &&
      applicationStatusRouteMatch.isExact
    ) {
      void queryApplicationStatus();
    }
  }, [
    applicationStatus,
    applicationStatusRouteMatch,
    authBackend,
    queryApplicationStatus,
  ]);

  const toggleAppInstall = useCallback(
    (app: IApplication) => {
      setInstalledApps((prev) => {
        const foundApp = prev.find((a) => a.id === app.id);
        if (foundApp) {
          return prev.filter((a) => a.id !== app.id);
        } else {
          return [...prev, app];
        }
      });
    },
    [setInstalledApps]
  );

  const toggleInstalledAppOpen = useCallback((app: IApplication) => {
    setSelectedInstalledApp((prev) => {
      return prev?.id === app.id ? undefined : app;
    });
  }, []);

  const getInstalledStatus = useCallback(
    (app: IApplication): boolean => {
      return Boolean(installedApps.find((a) => a.id === app.id));
    },
    [installedApps]
  );

  const { hasPermission, isFetched } = useCheckPermissions({
    permissions: FrontEndApps.map((app) => ({
      type: 'datacosmos_app' as const,
      actionScope: 'data:app:execute' as const,
      id: app.permissionsResourceId ?? app.name,
    })),
  });

  useEffect(() => {
    if (!isFetched) return;
    /* Conida users will have permissions assigned on a app-by-app basis */
    if (hasPermission.every((allowed) => !allowed)) {
      setInstalledApps([]);
    }
    const allowedApps = FrontEndApps.filter((_, index) => hasPermission[index]);
    setApplicationList(allowedApps);
  }, [
    hasPermission,
    setInstalledApps,
    FrontEndApps,
    setApplicationList,
    isFetched,
  ]);

  const switchToApplicationRuns = () => {
    setIsApplicationRunsOpen(true);
  };

  return {
    applicationList,
    setInputData,
    applicationStatusResult,
    queryApplicationStatus,
    isSubscribeModalOpen,
    subscribeApplicationName,
    openSubscribeModal,
    closeSubscribeModal,
    applicationAOIs,
    setApplicationAOIs,
    installedApps,
    toggleAppInstall,
    getInstalledStatus,
    toggleInstalledAppOpen,
    selectedInstalledApp,
    setSelectedInstalledApp,
    setShouldAutoOpen,
    shouldAutoOpen,
    isAllowedToAccessApplications: applicationList.length > 0,
    getApplicationRunStatus,
    isApplicationRunsOpen,
    setIsApplicationRunsOpen,
    switchToApplicationRuns,
  };
};

export type UseApplicationCatalogProvider =
  typeof useApplicationCatalogProvider;

export const ApplicationCatalogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ApplicationCatalogContext.Provider value={useApplicationCatalogProvider()}>
      {children}
    </ApplicationCatalogContext.Provider>
  );
};
