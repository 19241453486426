import { Layer, LayerSourceType } from 'datacosmos/entities/layer';
import { useMap } from 'datacosmos/stores/MapProvider';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import s from './index.module.scss';
import DraggableItem from './DraggableItem';
import LayerGridCard from './LayerCards/LayerGridCard';
import LayerListCard from './LayerCards/LayerListCard';
import CommonLayerControls from './CommonLayerControls';
import SidebarHeader from '_organisms/SidebarHeader/SidebarHeader';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { useTimeSeries } from 'datacosmos/stores/TimeSeriesProvider';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useLayerTools } from 'datacosmos/stores/LayerToolsProvider';

export type GroupHeaderItem = {
  id: string;
  type: 'group';
  label: string;
};

export function Layers() {
  const { layers, replaceLayer, setLayersMenuOpen, selectLayer } =
    useMapLayers();
  const { setSelectedBands } = useLayerTools();

  const [isLayerGridView, setIsLayerGridView] = useLocalStorage(
    'isLayerGridView',
    true
  );

  const { translate } = useLocalisation();
  const { isTimeSeriesToggled, timeSeriesLayers } = useTimeSeries();
  const {
    isLayersComparisonToggled,
    layersComparisonGroups,
    updateComparisonGroups,
  } = useMap();

  const layersMenuDisplayableLayers = layers.filter(
    (l) =>
      l.sourceType !== LayerSourceType.ASSET_OUTLINE &&
      l.sourceType !== LayerSourceType.PIXEL_MODE_MARKER
  );

  const items: (Layer | GroupHeaderItem)[] = isLayersComparisonToggled
    ? [
        {
          id: 'left',
          type: 'group',
          label: translate('datacosmos.layers.left'),
        },
        ...layersMenuDisplayableLayers.filter((l) =>
          layersComparisonGroups?.[0].includes(l.id)
        ),
        {
          id: 'right',
          type: 'group',
          label: translate('datacosmos.layers.right'),
        },
        ...layersMenuDisplayableLayers.filter((l) =>
          layersComparisonGroups?.[1].includes(l.id)
        ),
      ]
    : layersMenuDisplayableLayers;

  const renderItem = (item: Layer | GroupHeaderItem) => {
    if (item instanceof Layer) {
      return isLayerGridView ? (
        <LayerGridCard layer={item as Layer} />
      ) : (
        <LayerListCard layer={item as Layer} />
      );
    }
    return (
      <li className="w-full flex items-center dark:text-item-dark-contrast">
        <span>{item.label}</span>
      </li>
    );
  };

  const handleMoveItem = (fromIndex: number, toIndex: number) => {
    if (!isLayersComparisonToggled) {
      replaceLayer(layers[fromIndex], toIndex);
      return;
    }
    const item = items[fromIndex];
    const rightGroupHeaderIndex = items.findIndex((x) => x.id === 'right');
    const newItems = [...items];
    newItems.splice(fromIndex, 1);
    newItems.splice(toIndex, 0, item);
    const newRightGroupHeaderIndex = newItems.findIndex(
      (x) => x.id === 'right'
    );
    if (item.id === 'right') {
      updateComparisonGroups(
        newItems
          .slice(0, newRightGroupHeaderIndex)
          .filter((i) => i instanceof Layer)
          .map((i) => i.id),
        newItems
          .slice(newRightGroupHeaderIndex)
          .filter((i) => i instanceof Layer)
          .map((i) => i.id)
      );
    } else {
      if (rightGroupHeaderIndex !== newRightGroupHeaderIndex) {
        updateComparisonGroups(
          newItems
            .slice(0, newRightGroupHeaderIndex)
            .filter((i) => i instanceof Layer)
            .map((i) => i.id),
          newItems
            .slice(newRightGroupHeaderIndex)
            .filter((i) => i instanceof Layer)
            .map((i) => i.id)
        );
      }
      replaceLayer(
        item as Layer,
        toIndex - 1 - (toIndex < newRightGroupHeaderIndex ? 0 : 1) // subtract group headers
      );
    }
  };

  return (
    <div
      className={s.container}
      style={{ maxHeight: 'inherit', overflowY: 'auto' }}
    >
      <SidebarHeader
        title={translate('datacosmos.layers.layers')}
        forLayers={{ isLayerGridView, setIsLayerGridView }}
        rightIcon={{
          iconName: 'ChevronRightDouble',
          onIconClick: () => setLayersMenuOpen(false),
        }}
        iconTooltipContent={translate('datacosmos.tooltips.header.collapse')}
      />
      <CommonLayerControls layers={layers} />
      <DndProvider backend={HTML5Backend}>
        <ul className={s.layerList + ' bg-surface dark:bg-surface-dark'}>
          {(isTimeSeriesToggled ? timeSeriesLayers : items).map((item, i) => (
            <DraggableItem
              key={item.id}
              index={i}
              item={item}
              disabled={item.id === 'left'}
              moveItem={handleMoveItem}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                if (item instanceof Layer) {
                  e.ctrlKey || e.metaKey
                    ? selectLayer(item, true)
                    : selectLayer(item, false);
                  setSelectedBands([]);
                }
              }}
              isSelected={item instanceof Layer && item.options?.isSelected}
              heightWhileDragging={'type' in item ? 125 : undefined}
            >
              {renderItem(item)}
            </DraggableItem>
          ))}
        </ul>
      </DndProvider>
    </div>
  );
}
