import toKml from 'tokml';
import shpwrite from '@mapbox/shp-write';
import JSZip from 'jszip';
import type { Layer } from 'datacosmos/entities/layer';
import type { ViewLayer } from '_api/views/types';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { CircleLayer } from 'datacosmos/entities/circleLayer';
import { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { LineLayer } from 'datacosmos/entities/lineLayer';
import { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import {
  geoJsonFeatureToGeoJsonFeatureCollection,
  getTwoMostSouthernPointsOrdered,
} from 'datacosmos/utils/geojson';
import conidaWatermark from 'public/images/datacosmos/conida-logo-98x98.png';
import { HOST_URL, LOGO } from 'env';
import OCLogo from 'public/images/logo-monogram-black.svg';

import { getThumbnailAssetKey } from 'datacosmos/utils/stac';
import bbox from '@turf/bbox';
import bearing from '@turf/bearing';
import transformRotate from '@turf/transform-rotate';
import { getFilenameFromURL } from 'utils/common/CommonUtils';

const OCLogoURL = HOST_URL + '/images/logo-monogram-black.svg';
const conidaWatermarkURL =
  HOST_URL + '/images/datacosmos/conida-logo-98x98.png';

const parser = new DOMParser();
const serializer = new XMLSerializer();

const pushToKML = (kmlData: string, element: string) => {
  const kmlWithImage = kmlData.replace('</Document>', `${element}</Document>`);
  return kmlWithImage;
};

const addScreenOverlayToKML = (
  kmlData: string,
  imageName: string,
  imageHref: string
) => {
  const screenOverlay = `
    <ScreenOverlay>
    <name>${imageName}</name>
    <Icon>
      <href>${imageHref}</href>
    </Icon>
    <overlayXY x="1" y="0" xunits="fraction" yunits="fraction" />
    <screenXY x="1" y="0" xunits="fraction" yunits="fraction" />
    <size x="0" y="0" xunits="fraction" yunits="fraction" />
      </ScreenOverlay>
  `;
  return pushToKML(kmlData, screenOverlay);
};

const replacePlacemarksWithGroundOverlaysInKML = (
  kmlDom: Document,
  imageHref: string,
  feature: GeoJSON.Feature
) => {
  if (feature.geometry.type !== 'Polygon') return;
  // Calculate the rotation using the bottom two points of the polygon
  const coordinates = feature.geometry.coordinates[0];
  const [p1, p2] = getTwoMostSouthernPointsOrdered(coordinates);
  const rotation = bearing(p1, p2) - 90;

  // Rotate the feature to align with the SRS axis
  const rotatedFeature = transformRotate(
    feature as GeoJSON.Feature<GeoJSON.Polygon>,
    -rotation
  );

  // Calculate the bounding box of the rotated feature
  const featureBbox = bbox(rotatedFeature);
  const [west, south, east, north] = featureBbox;

  // Rotation in LatLonBox is counter-clockwise, so we negate the rotation calculated by turf
  const groundOverlayString = `
    <GroundOverlay>
      <name>${String(
        feature.properties?.item_id ?? feature.properties?.created
      )}</name>
      <Icon>
        <href>${imageHref}</href>
      </Icon>
      <LatLonBox>
        <north>${north}</north>
        <south>${south}</south>
        <east>${east}</east>
        <west>${west}</west>
        <rotation>${-rotation}</rotation>
      </LatLonBox>
    </GroundOverlay>
  `;
  const groundOverlay = parser
    .parseFromString(groundOverlayString, 'text/xml')
    .getElementsByTagName('GroundOverlay')[0];
  // Find the Placemark with a <name> tag that matches the feature's item_id
  const nameElements = kmlDom.querySelectorAll('name');
  let placemark = null;
  nameElements.forEach((nameElement) => {
    const closestPlacemark = nameElement.closest('Placemark');
    if (
      nameElement.textContent === feature.properties?.item_id &&
      closestPlacemark
    ) {
      placemark = closestPlacemark;
    }
  });
  if (placemark) {
    // Get the feature's properties (ExtendedData), append them to
    // the GroundOverlay and remove the Placemark
    const extendedData = (placemark as Element).getElementsByTagName(
      'ExtendedData'
    )[0];
    groundOverlay.appendChild(extendedData);
    (placemark as Element)?.parentNode?.appendChild(groundOverlay);
    (placemark as Element)?.parentNode?.removeChild(placemark);
  }
};

const addWatermarktoKml = (kmlData: string, kmzFile?: JSZip) => {
  let imageHref;
  if (kmzFile) {
    const mark = LOGO ? conidaWatermark : OCLogo;
    const filename = `Watermark.${LOGO ? 'png' : 'svg'}`;
    kmzFile.file(filename, mark.split(',')[1], {
      base64: true,
    });
    imageHref = filename;
  } else {
    imageHref = LOGO ? conidaWatermarkURL : OCLogoURL;
  }
  return addScreenOverlayToKML(kmlData, 'Watermark', imageHref);
};

const addThumbnailsToKML = async (
  kmlData: string,
  geo: GeoJSON.GeoJSON,
  kmzFile?: JSZip
) => {
  if (geo.type === 'FeatureCollection') {
    const thumbnailedFeatures = geo.features.filter(
      (feature) => feature.properties?.thumbnail
    );
    const thumbnailPromises = thumbnailedFeatures.map(
      async (feature, index) => {
        const href = feature.properties?.thumbnail as string;
        const response = await fetch(href);
        const blob = await response.blob();
        const reader = new FileReader();
        return new Promise<{
          image: string;
          feature: GeoJSON.Feature;
          filename: string;
        }>((resolve) => {
          reader.onloadend = () => {
            const filename =
              getFilenameFromURL(href) ?? `thumbnail_${index}.png`;
            resolve({ image: reader.result as string, feature, filename });
          };
          reader.readAsDataURL(blob);
        });
      }
    );
    const kmlDom = parser.parseFromString(kmlData, 'text/xml');
    const thumbnails = await Promise.allSettled(thumbnailPromises);
    thumbnails.forEach((result) => {
      if (result.status === 'fulfilled' && result.value) {
        const { image, feature, filename } = result.value;
        replacePlacemarksWithGroundOverlaysInKML(
          kmlDom,
          kmzFile ? filename : image,
          feature
        );
        kmzFile?.file(filename, image.split(',')[1], {
          base64: true,
        });
      }
    });
    kmlData = serializer.serializeToString(kmlDom);
  }
  return kmlData;
};

/**
 * downloadFilUrlAsExtension takes a `fileUrl` and downloads it as `extension` to user's local machine
 * @param fileUrl File url to download, usually in a format `FILE_TYPE;ENCODING, FILE_CONTENTS` e.g. `data:application/zip;base64,UEsDBBQAAAAIA...`
 * @param fileName Desired file name for the file
 * @param extension Desired file extension e.g. `zip`
 */
export const downloadFilUrlAsExtension = (
  fileUrl: string,
  fileName: string,
  extension: string
) => {
  const downloadLink = document.createElement('a');

  downloadLink.href = `data:${fileUrl}`;
  downloadLink.download = `${fileName}.${extension}`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  downloadLink.remove();
};

/**
 * downloadGeojsonAsKml takes an arbitrary `geojson` file and downloads it as `.kml` to user's local machine
 * @param geo GeoJSON object to download
 * @param fileName Desired file name for the `.kml` file
 */
export const downloadGeojsonAsKml = (
  geo: GeoJSON.GeoJSON,
  fileName: string,
  includeWaterMark?: boolean
) => {
  const kml = toKml(geo);
  const kmlString = 'application/vnd.google-earth.kml+xml;charset=utf-8,';

  const modifiedKml = includeWaterMark ? addWatermarktoKml(kml) : kml;
  const kmlConverted = kmlString + encodeURIComponent(modifiedKml);
  downloadFilUrlAsExtension(kmlConverted, fileName, 'kml');
};

/**
 * downloadGeojsonAsShp takes an arbitrary `geojson` file and downloads it as `.zip`
 * containing all the necessary shapefile related files to user's local machine
 * @param geo GeoJSON object to download
 * @param fileName Desired file name for the `.zip` file
 */
export const downloadGeojsonAsShp = async (
  geo: GeoJSON.FeatureCollection,
  fileName: string
) => {
  const shp = (await shpwrite.zip(geo, {
    outputType: 'base64',
    compression: 'DEFLATE',
  })) as string;

  const shpUrl = `application/zip;base64, ${shp}`;
  downloadFilUrlAsExtension(shpUrl, fileName, 'zip');
};

/**
 * downloadGeojsonAsKmz takes an arbitrary `geojson` file and downloads it as `.kmz`
 * containing the zipped kml files to user's local machine
 * @param geo GeoJSON object to download
 * @param fileName Desired file name for the `.kmz` file
 */
export const downloadGeojsonAsKmz = async (
  geo: GeoJSON.GeoJSON,
  fileName: string,
  includeWaterMark?: boolean,
  includeThumbnails?: boolean
) => {
  let kml = toKml(geo, { name: 'item_id' });
  const kmz = new JSZip();

  kml = includeThumbnails ? await addThumbnailsToKML(kml, geo, kmz) : kml;
  kml = includeWaterMark ? addWatermarktoKml(kml, kmz) : kml;

  kmz.file(`${fileName}.kml`, kml);

  const kmzData = await kmz.generateAsync({ type: 'base64' });
  const kmzURL = `application/vnd.google-earth.kmz;base64, ${kmzData}`;
  downloadFilUrlAsExtension(kmzURL, fileName, 'kmz');
};

/**
 * downloadAOIasGeojson takes an arbitrary `geojson` file and downloads it as `.geojson`
 * @param geo GeoJSON object to download
 * @param fileName Desired file name for the `.geojson` file
 */
export const downloadAOIasGeojson = (
  geo: GeoJSON.GeoJSON,
  fileName: string
) => {
  const geojsonURL = `application/vnd.geo+json;charset=utf-8, ${encodeURIComponent(
    JSON.stringify(geo)
  )}`;

  downloadFilUrlAsExtension(geojsonURL, fileName, 'geojson');
};

const getItemThumbnail = (
  layer: SingleBandSTACLayer | BandAlgebraSTACLayer
) => {
  // TODO: The preview service returns a mirrored image,
  // we would need to flip it to use it as thumbnail
  // if (layer instanceof BandAlgebraSTACLayer) {
  //   return getBandAlgebraLayerPreview(layer);
  // }
  const thumbKey = getThumbnailAssetKey(layer.item);
  return thumbKey ? layer.item.assets[thumbKey].href : '';
};

export const convertLayersToFeatureCollection = (
  layers: ViewLayer[] | Layer[]
) => {
  const featureCollection: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };
  const layersToDownload = [...layers];
  layersToDownload.map((layer) => {
    if (
      layer instanceof SingleBandSTACLayer ||
      layer instanceof BandAlgebraSTACLayer
    ) {
      const noDataPropertyLayer = layer;
      noDataPropertyLayer.item.properties = {
        ...noDataPropertyLayer.item.properties,
        item_id: noDataPropertyLayer.item.id,
        collection_id: noDataPropertyLayer.item.collection,
      };
      // Check and stringify properties
      for (const key in noDataPropertyLayer.item.properties) {
        const val: string | object = noDataPropertyLayer.item.properties[
          key
        ] as string | object;
        noDataPropertyLayer.item.properties[key] =
          typeof val === 'object' ? JSON.stringify(val) : val;
      }
      const thumbnail = getItemThumbnail(noDataPropertyLayer);
      const properties = {
        ...noDataPropertyLayer.item.properties,
        ...(thumbnail && { thumbnail }),
      };
      featureCollection.features.push({
        geometry: noDataPropertyLayer.item.geometry as GeoJSON.Geometry,
        properties,
        type: 'Feature',
      });
    } else if (
      layer instanceof CircleLayer ||
      layer instanceof PolygonLayer ||
      layer instanceof LineLayer ||
      layer instanceof GeoJSONLayer
    ) {
      const dataPropertyLayer = layer;
      (dataPropertyLayer.data as GeoJSON.Feature).properties = {
        id: dataPropertyLayer.id,
        name: dataPropertyLayer.name,
      };
      featureCollection.features.push(
        dataPropertyLayer.data as GeoJSON.Feature
      );
    }
  });
  return featureCollection;
};

export const getShpBase64 = async (geo: GeoJSON.Feature) => {
  const featCol = geoJsonFeatureToGeoJsonFeatureCollection(geo);
  const shp = await shpwrite.zip(featCol, {
    outputType: 'base64',
    compression: 'DEFLATE',
  });
  return shp;
};
