import React from 'react';
import { Select, ListBoxItem } from 'opencosmos-ui';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { AEROSOL_PROFILES } from 'datacosmos/components/Applications/utils';
import type { Key } from '@react-types/shared';

interface AerosolProfileSelectorProps {
  value: number;
  onChange: (value: number | Key) => void;
}

const AerosolProfileSelector = ({
  value,
  onChange,
}: AerosolProfileSelectorProps) => {
  const { translate } = useLocalisation();
  return (
    <Select
      fill
      name="aerosol-profile"
      label={translate(
        'datacosmos.applications.aerosolParameters.profile.label'
      )}
      selectedKey={value}
      onSelectionChange={onChange}
    >
      {AEROSOL_PROFILES.map((profile) => (
        <ListBoxItem key={'aerosolProfile' + profile} id={profile}>
          {translate(
            `datacosmos.applications.aerosolParameters.profile.options.${profile}`
          )}
        </ListBoxItem>
      ))}
    </Select>
  );
};

export default AerosolProfileSelector;
