import area from '@turf/area';
import intersect from '@turf/intersect';
import union from '@turf/union';
import type { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';

export const isPoint = (data: GeoJSON.GeoJSON) => {
  if (data.type === 'Feature') {
    return data.geometry.type === 'Point';
  }
  return data.type === 'Point';
};

export const isFeatureCollectionArray = (
  collection: ({ features?: unknown } | undefined)[]
): collection is GeoJSON.FeatureCollection[] => {
  return collection.every((value) => Boolean(value?.features));
};

export const isFeatureArray = (
  feature: ({ type?: string } | undefined)[]
): feature is GeoJSON.Feature[] => {
  return feature.every((feat) => feat?.type === 'Feature');
};

export const isGeometryCollection = (geojson: {
  type: string;
}): geojson is GeoJSON.GeometryCollection => {
  return geojson.type === 'GeometryCollection';
};

export const geometryCollectionToGeometry = (
  geometryCol: GeoJSON.GeometryCollection
) => {
  const reduced = (geometryCol.geometries as GeoJSON.Polygon[]).reduce(
    (acc, col) => {
      if (!acc.coordinates) {
        acc = col;
      } else {
        acc = {
          type: acc.type,
          coordinates: [...acc.coordinates, ...col.coordinates],
        };
      }

      return acc;
    },
    {} as GeoJSON.Polygon
  );

  return reduced;
};

/**
 * getLargestPolygonFromMultipolygon takes a multi polygon and returns the largest one
 * @param multiPoly GeoJSON.MultiPolygon
 * @returns GeoJSON.Polygon
 */
export const getLargestPolygonFromMultipolygon = (
  multiPoly: GeoJSON.MultiPolygon
) => {
  const polygons: GeoJSON.Polygon[] = multiPoly.coordinates.map((poly) => ({
    type: 'Polygon',
    coordinates: poly,
    properties: {},
  }));

  const largest = polygons.sort((a, b) => {
    return area(b) - area(a);
  })[0];

  return largest;
};

export const polygonToFeature = (
  poly: GeoJSON.Polygon
): GeoJSON.Feature<GeoJSON.Polygon> => {
  return {
    geometry: poly,
    properties: {},
    type: 'Feature',
  };
};

export const polygonToFeatureCollection = (
  poly: GeoJSON.Polygon
): GeoJSON.FeatureCollection<GeoJSON.Polygon> => {
  return {
    features: [{ geometry: poly, properties: {}, type: 'Feature' }],
    type: 'FeatureCollection',
  };
};
/**
 * geoJsonFeatureToGeoJsonFeatureCollection takes a GeoJSON.Feature and returns a GeoJSON.FeatureCollection
 * @param feat feature to convert to feature collection
 * @returns GeoJSON.FeatureCollection
 */
export const geoJsonFeatureToGeoJsonFeatureCollection = (
  feat: GeoJSON.Feature
): GeoJSON.FeatureCollection => {
  return {
    type: 'FeatureCollection',
    features: [feat],
  };
};

/**
 * Returns the two most southern points from a list of coordinates, ordered by longitude.
 *
 * @param coordinates - An array of coordinate pairs, where each pair is an array of two numbers [longitude, latitude].
 * @returns An array containing the two most southern points, ordered by longitude.
 */
export const getTwoMostSouthernPointsOrdered = (
  coordinates: GeoJSON.Position[]
): GeoJSON.Position[] => {
  const sortedByLatitude = [...coordinates].sort((a, b) => a[1] - b[1]);
  const twoMostSouthernPoints = sortedByLatitude.slice(0, 2);
  return twoMostSouthernPoints.sort((a, b) => a[0] - b[0]);
};
