import { Input } from 'opencosmos-ui';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface AerosolOpticalThicknessInputProps {
  value: number;
  onChange: (value: number) => void;
}

const AerosolOpticalThicknessInput = ({
  value,
  onChange,
}: AerosolOpticalThicknessInputProps) => {
  const { translate } = useLocalisation();
  return (
    <Input
      type="number"
      value={value}
      onChange={(e) => onChange(parseFloat(e.target.value))}
      min={0}
      label={{
        position: 'top',
        text: translate(
          'datacosmos.applications.aerosolParameters.opticalThickness.label'
        ),
      }}
    />
  );
};

export default AerosolOpticalThicknessInput;
