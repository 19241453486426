export default {
  environment: 'production',
  map: {
    initialZoom: 3,
    minZoom: 2,
    maxZoom: 20,
    maxNativeZoomBasedOnReadPermission: 11,
    initialView: [-18.632, 18.479] as [number, number],
  },
};
