import React, { useCallback, useState } from 'react';

import conidaLogo from 'public/images/datacosmos/conida-logo.png';
import type { IAPIAppValue, IApplication } from 'datacosmos/types/applications';
import { btoaSafe } from 'utils/common/btoaSafe';
import { clientTranslate, useLocalisation } from 'utils/hooks/useLocalisation';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import OpenedAppCard from '../SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from '../SubscriptionApps/Common/UnopenedAppCard';
import { Input } from 'opencosmos-ui';
import { submitWorkflow } from '_api/pdgs/service';
import { useProjects } from 'datacosmos/stores/ProjectProvider';

type Props = {
  app: IApplication;
};

export const SPOTIngestionApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name +
          JSON.stringify(this.provider) +
          this.description +
          this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: clientTranslate('datacosmos.applications.spotIngestion.title'),
  description: clientTranslate(
    'datacosmos.applications.spotIngestion.description'
  ),
  inputs: [
    {
      field: 'product-filename',
      example: '',
    },
  ],
  values: {
    'product-filename': { value: '', isError: false, message: '' },
  },
  provider: {
    id: 1,
    // If the name is blank, the logo will be full width.
    name: '',
    description:
      'Comisión Nacional de Investigación y Desarrollo Aeroespacial del Perú',
    url: 'https://www.gob.pe/conida',
    icon_url: conidaLogo,
  },
  shortDescription: clientTranslate(
    'datacosmos.applications.spotIngestion.shortDescription'
  ),
  renderer: (app: IApplication) => <SPOTIngestion app={app} />,
  appScreenshotUrl: '',
  tags: [],
  isTraceableWorkflow: true,
  acronym: 'SPO',
  permissionsResourceId: 'spot-sen',
};

const SPOTIngestion = ({ app }: Props) => {
  const {
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
    switchToApplicationRuns,
  } = useApplicationCatalog();

  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);
  const [isSubmittingWorkflow, setIsSubmittingWorkflow] =
    useState<boolean>(false);
  const { translate } = useLocalisation();
  const { currentScenario } = useProjects();

  const setValue = useCallback(
    (key: string, value: IAPIAppValue['value']) => {
      setInputData(app.name, {
        ...app.values,
        [key]: { value, isError: false, message: '' },
      });
    },
    [app.name, app.values, setInputData]
  );

  const resetInputValues = useCallback(() => {
    setValue('product-filename', '');
  }, [setValue]);

  const inputs = useCallback(() => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div className="flex flex-col gap-1">
          <Input
            type="text"
            value={app.values['product-filename']?.value as string}
            onChange={(e) => {
              setValue('product-filename', e.target.value);
            }}
            label={{
              position: 'top',
              text: translate(
                'datacosmos.applications.spotIngestion.inputs.zipFile'
              ),
              wrapLabelText: true,
            }}
          />
          {app.values['product-filename']?.isError && (
            <div>
              <small className="text-warning">
                {app.values['product-filename'].message}
              </small>
            </div>
          )}
        </div>
      </div>
    );
  }, [app.values, setValue, translate]);

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        toggleAppInstall={toggleAppInstall}
        isInstalled={getInstalledStatus(app)}
        handleSubmit={async () => {
          if (!currentScenario) {
            return;
          }
          setIsSubmittingWorkflow(true);
          const { success } = await submitWorkflow({
            body: {
              name: 'spot-sen',
              inputs: {
                'product-filename': app.values['product-filename']
                  ?.value as string,
              },
              labels: {
                'app.opencosmos.com/project_id': currentScenario?.id,
              },
            },
          });
          resetInputValues();
          setIsSubmittingWorkflow(false);

          if (success && app.isTraceableWorkflow) {
            switchToApplicationRuns();
          }
        }}
        submitButtonLabel={translate(
          'datacosmos.applications.global.buttons.submit'
        )}
        loading={isSubmittingWorkflow}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      isInstalled={getInstalledStatus(app)}
      setIsAppOpened={setIsAppOpened}
      setSelectedInstalledApp={setSelectedInstalledApp}
      toggleAppInstall={toggleAppInstall}
    />
  );
};

export default SPOTIngestion;
