import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useState } from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { UpdateUsersLevel } from '_api/users/service';
import RadioInput from '_molecules/RadioInput/RadioInput';
import { Dialog } from 'opencosmos-ui';

export type UpgradeUserPermissionProps = {
  text: string;
  openModalByDefault: boolean;
  closeModal?: (open: boolean) => void;
};

export const UpgradeUserPermission = ({
  text,
  openModalByDefault,
  closeModal,
}: UpgradeUserPermissionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(openModalByDefault);
  const [selecteduserType, setSelectedUserType] = useState<string>('');
  const { translate } = useLocalisation();

  const handleUserUpgradeRequest = () => {
    void UpdateUsersLevel({
      params: { permission: selecteduserType },
    });
    setIsOpen(false);
    if (closeModal) {
      closeModal(false);
    }
    window.location.reload();
  };

  const RadioItemsList = [
    {
      name: translate('datacosmos.upgradePermissionsDialog.userType.private'),
      value: 'private',
      disabled: false,
      description: translate(
        'datacosmos.upgradePermissionsDialog.userType.privateTooltipText'
      ),
    },
    {
      name: translate(
        'datacosmos.upgradePermissionsDialog.userType.institutional'
      ),
      value: 'institution',
      disabled: false,
      description: translate(
        'datacosmos.upgradePermissionsDialog.userType.institutionalTooltipText'
      ),
    },
  ];

  return (
    <>
      <a
        target="_blank"
        className="flex items-center p-2 dark:text-item-dark-contrast hover:no-underline whitespace-nowrap"
        rel="noreferrer"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Icon className="mr-1" icon={IconNames.CircleArrowUp} />
        {text}
      </a>

      <Dialog
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          if (closeModal) {
            closeModal(false);
          }
        }}
        title={translate(
          'datacosmos.upgradePermissionsDialog.upgradePermissionsHeader'
        )}
        buttons={[
          {
            text: translate(
              'datacosmos.upgradePermissionsDialog.sendButtonText'
            ),
            onPress: () => handleUserUpgradeRequest(),
            shown: selecteduserType === 'private',
          },
          {
            text: translate(
              'datacosmos.upgradePermissionsDialog.sendEmailText'
            ),
            onPress: () => {},
            shown: selecteduserType === 'institution',
            href: 'mailto:atencionalcliente@conida.gob.pe',
          },
        ]}
      >
        <div className="px-4">
          <div>
            {translate('datacosmos.upgradePermissionsDialog.permissionsBody')}
          </div>
          <div className="mt-8">
            <RadioInput
              style={{ height: '100%' }}
              onChange={(event) => {
                setSelectedUserType(event.target.value);
              }}
              radioName="userType"
              radioItems={RadioItemsList}
              label={{
                text: translate(
                  'datacosmos.upgradePermissionsDialog.typeOfUser'
                ),
                position: 'top',
              }}
              className="my-2 flex items-center gap-2"
            />
          </div>

          {selecteduserType === 'institution' && (
            <div
              className="mt-4"
              dangerouslySetInnerHTML={{
                __html: `${translate(
                  'datacosmos.upgradePermissionsDialog.downloadAndFillDocument'
                )}`,
              }}
            ></div>
          )}
              

              {selecteduserType === 'institution' && (
            <div className="flex items-center gap-2 bg-accent-200 dark:bg-accent-dark my-2 p-1 text-xs dark:text-item-contrast">
              <Icon icon="info-sign" />
              <span>
                {translate(
                  'datacosmos.upgradePermissionsDialog.ignoreIfEmailSent'
                )}
                </span>
            </div>
        )}
        </div>
      </Dialog>
    </>
  );
};

export default UpgradeUserPermission;
