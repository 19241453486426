import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useSingleImagePixels } from 'datacosmos/utils/hooks/pixelModes/useSingleImagePixels';
import { useClickedStacItem } from 'datacosmos/utils/hooks/useClickedStacItem';
import { useLinePixels } from 'datacosmos/utils/hooks/pixelModes/useLinePixels';
import type { BBox } from 'geojson';
import { useSinglePixelVsWavelength } from 'datacosmos/utils/hooks/pixelModes/useSinglePixelVsWavelength';
import { useSpectralSignatures } from 'datacosmos/utils/hooks/pixelModes/useSpectralSignatures';
import { useDrawStacItem } from 'datacosmos/utils/hooks/useDrawStacItem';
import { toaster } from 'toaster';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useCustomLinePixels } from 'datacosmos/utils/hooks/pixelModes/useCustomLinePixels';
import { isGeoreferencedWithGCP } from 'datacosmos/utils/stac';

export type ClickedItemData = {
  id: string | undefined;
  collection: string | undefined;
  url: string | undefined;
  bbox: BBox | undefined;
  geometry: GeoJSON.Polygon | undefined;
  isAllowedToPerformPixelOperations: boolean;
  isItemGeoreferenced: boolean;
};

export type PixelModeContextType = ReturnType<typeof usePixelModeProvider>;
export type PixelMode =
  | 'SingleImagePixel'
  | 'LinePixelData'
  | 'CustomLinePixelData'
  | 'vsWavelength'
  | 'spectralSignatures';

export const PixelModeContext = createContext<PixelModeContextType>(
  null as unknown as PixelModeContextType
);

export const usePixelMode = () => useContext(PixelModeContext);

export const usePixelModeProvider = () => {
  const { translate } = useLocalisation();
  const {
    aoi,
    drawnStacLayer,
    layersContainStacItems: isDrawEnabled,
    resetDrawnLayerAndAOI,
  } = useDrawStacItem();

  const {
    getClickedPointPixelData,
    toggleSingleImagePixelMode,
    isToggled: isSingleImagePixelModeToggled,
  } = useSingleImagePixels();

  const {
    toggleLinePixelValuesMode,
    isToggled: isLinePixelValuesModeToggled,
    getSelectedLinePixelData,
    linePixelsChartData,
    isFetching: isFetchingLinePixels,
  } = useLinePixels();

  const {
    toggleCustomLinePixelValuesMode,
    isToggled: isCustomLinePixelValuesModeToggled,
    getLineOfInterestPixelData,
    customLinePixelsChartData,
    isFetching: isFetchingCustomLinePixels,
  } = useCustomLinePixels();

  const {
    getPixelValuesVsWavelength,
    isFetching: isFetchingVsWavelength,
    isToggled: isVsWavelengthToggled,
    singlePixelVsWavelengthChartData,
    toggleSinglePixelVsWavelength,
  } = useSinglePixelVsWavelength();

  const {
    getPixelValuesSpectralSignatures,
    isFetching: isFetchingSpectralSignatures,
    isToggled: isSpectralSignaturesToggled,
    pixelSpectralSignaturesChartData,
    togglePixelSpectralSignatures,
  } = useSpectralSignatures();

  const {
    clickedPoint,
    clickedStacLayer,
    layersContainStacItems: isEnabled,
    resetClickedLayerAndPoint,
  } = useClickedStacItem({ isDisabled: isSpectralSignaturesToggled });

  const isAnyOfTheModesToggled = useMemo(
    () =>
      isSingleImagePixelModeToggled ||
      isLinePixelValuesModeToggled ||
      isCustomLinePixelValuesModeToggled ||
      isVsWavelengthToggled ||
      isSpectralSignaturesToggled,
    [
      isLinePixelValuesModeToggled,
      isCustomLinePixelValuesModeToggled,
      isSingleImagePixelModeToggled,
      isVsWavelengthToggled,
      isSpectralSignaturesToggled,
    ]
  );

  const getClickedLayerItemData = useCallback(
    (clickedLayer: SingleBandSTACLayer): ClickedItemData => {
      return {
        id: clickedLayer.item.id,
        collection: clickedLayer.item.collection,
        url: clickedLayer.item.assets[clickedLayer.assetKey].href,
        bbox: clickedLayer.item.bbox,
        geometry: clickedLayer.item.geometry as GeoJSON.Polygon,
        isAllowedToPerformPixelOperations: clickedLayer.item.properties[
          'opencosmos:high_resolution_read_permission'
        ]! as boolean,
        isItemGeoreferenced: !isGeoreferencedWithGCP(clickedLayer.item),
      };
    },
    []
  );

  const togglePixelMode = useCallback(
    (mode: PixelMode) => {
      switch (mode) {
        case 'SingleImagePixel': {
          toggleSingleImagePixelMode();
          toggleLinePixelValuesMode(false);
          toggleSinglePixelVsWavelength(false);
          togglePixelSpectralSignatures(false);
          toggleCustomLinePixelValuesMode(false);
          break;
        }
        case 'LinePixelData': {
          toggleLinePixelValuesMode();
          toggleSingleImagePixelMode(false);
          toggleSinglePixelVsWavelength(false);
          togglePixelSpectralSignatures(false);
          toggleCustomLinePixelValuesMode(false);
          break;
        }
        case 'CustomLinePixelData': {
          toggleCustomLinePixelValuesMode();
          toggleLinePixelValuesMode(false);
          toggleSingleImagePixelMode(false);
          toggleSinglePixelVsWavelength(false);
          togglePixelSpectralSignatures(false);
          break;
        }
        case 'vsWavelength': {
          toggleSinglePixelVsWavelength();
          toggleLinePixelValuesMode(false);
          toggleSingleImagePixelMode(false);
          togglePixelSpectralSignatures(false);
          toggleCustomLinePixelValuesMode(false);
          break;
        }
        case 'spectralSignatures': {
          togglePixelSpectralSignatures();
          toggleSinglePixelVsWavelength(false);
          toggleLinePixelValuesMode(false);
          toggleSingleImagePixelMode(false);
          toggleCustomLinePixelValuesMode(false);
          break;
        }
      }
    },
    [
      toggleLinePixelValuesMode,
      toggleCustomLinePixelValuesMode,
      toggleSingleImagePixelMode,
      toggleSinglePixelVsWavelength,
      togglePixelSpectralSignatures,
    ]
  );

  const toggleOffAllModes = useCallback(() => {
    toggleSingleImagePixelMode(false);
    toggleLinePixelValuesMode(false);
    toggleCustomLinePixelValuesMode(false);
    toggleSinglePixelVsWavelength(false);
    togglePixelSpectralSignatures(false);
  }, [
    toggleLinePixelValuesMode,
    toggleCustomLinePixelValuesMode,
    toggleSingleImagePixelMode,
    toggleSinglePixelVsWavelength,
    togglePixelSpectralSignatures,
  ]);

  const handlePixelModes = useCallback(
    (layerItemData: ClickedItemData) => {
      const {
        url,
        geometry,
        collection,
        id,
        isAllowedToPerformPixelOperations,
        isItemGeoreferenced,
      } = layerItemData;
      if (!isAllowedToPerformPixelOperations) {
        toaster.show({
          message: translate('datacosmos.layers.pixelOperations.noPermissions'),
          icon: 'warning-sign',
          intent: 'warning',
        });
        return;
      }
      if ((isVsWavelengthToggled || isSpectralSignaturesToggled) && !isItemGeoreferenced) {
        toaster.show({
          message: translate('datacosmos.layers.pixelOperations.notGeoreferenced'),
          icon: 'warning-sign',
          intent: 'warning',
        });
        return;
      }
      if (isSingleImagePixelModeToggled) {
        void getClickedPointPixelData(
          clickedPoint!.lat,
          clickedPoint!.lng,
          url
        );
        return;
      }

      if (isLinePixelValuesModeToggled) {
        void getSelectedLinePixelData(clickedPoint!, geometry, url);
        return;
      }

      if (
        isCustomLinePixelValuesModeToggled &&
        aoi &&
        aoi.geometry.type === 'LineString'
      ) {
        void getLineOfInterestPixelData(aoi, url);
      }

      if (isVsWavelengthToggled) {
        void getPixelValuesVsWavelength(clickedPoint!, collection, id);
        return;
      }

      if (isSpectralSignaturesToggled && aoi) {
        void getPixelValuesSpectralSignatures(aoi, collection, id);
        return;
      }
    },
    [
      clickedPoint,
      aoi,
      getClickedPointPixelData,
      getPixelValuesVsWavelength,
      getSelectedLinePixelData,
      getLineOfInterestPixelData,
      getPixelValuesSpectralSignatures,
      isLinePixelValuesModeToggled,
      isCustomLinePixelValuesModeToggled,
      isSingleImagePixelModeToggled,
      isVsWavelengthToggled,
      isSpectralSignaturesToggled,
      translate,
    ]
  );

  useEffect(() => {
    if (!isEnabled) {
      toggleOffAllModes();
    }
  }, [isEnabled, toggleOffAllModes]);

  useEffect(() => {
    if (
      !clickedPoint ||
      !clickedStacLayer ||
      !isEnabled ||
      !isAnyOfTheModesToggled
    ) {
      resetClickedLayerAndPoint();
      return;
    }

    const data = getClickedLayerItemData(clickedStacLayer);
    handlePixelModes(data);
  }, [
    clickedPoint,
    clickedStacLayer,
    getClickedLayerItemData,
    handlePixelModes,
    isAnyOfTheModesToggled,
    isEnabled,
    resetClickedLayerAndPoint,
  ]);

  useEffect(() => {
    if (!aoi || !drawnStacLayer || !isDrawEnabled || !isAnyOfTheModesToggled) {
      resetDrawnLayerAndAOI();
      return;
    }

    if (isSpectralSignaturesToggled || isCustomLinePixelValuesModeToggled) {
      const data = getClickedLayerItemData(drawnStacLayer);
      handlePixelModes(data);
    }
  }, [
    aoi,
    drawnStacLayer,
    getClickedLayerItemData,
    handlePixelModes,
    isAnyOfTheModesToggled,
    isDrawEnabled,
    resetDrawnLayerAndAOI,
    isSpectralSignaturesToggled,
    isCustomLinePixelValuesModeToggled,
  ]);

  return {
    aoi,
    isEnabled,
    isDrawEnabled,
    isSingleImagePixelModeToggled,
    isLinePixelValuesModeToggled,
    isFetchingLinePixels,
    linePixelsChartData,
    togglePixelMode,
    isFetchingVsWavelength,
    isFetchingSpectralSignatures,
    singlePixelVsWavelengthChartData,
    isVsWavelengthToggled,
    isSpectralSignaturesToggled,
    pixelSpectralSignaturesChartData,
    isCustomLinePixelValuesModeToggled,
    isFetchingCustomLinePixels,
    customLinePixelsChartData,
  };
};

export const PixelModeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const data = usePixelModeProvider();

  return (
    <PixelModeContext.Provider value={data}>
      {children}
    </PixelModeContext.Provider>
  );
};
