import { useEffect, useState, useMemo, Fragment } from 'react';
import { NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import useFeatureGroup from 'datacosmos/services/useFeatureGroup';
import { useFilters } from 'datacosmos/stores/FiltersProvider';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useMapController } from 'datacosmos/stores/MapControllerProvider';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import {
  getOverviewAssetKey,
  getVisualAssetKey,
  hasOverviewRoleAsset,
} from 'datacosmos/utils/stac';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import InfiniteScroll from 'react-infinite-scroller';
import HomeBlurb from 'datacosmos/components/HomeBlurb';
import { ResultsListCard } from 'datacosmos/components/ItemListCard/ResultsListCard';
import type { IStacItem, StacItem } from 'datacosmos/types/stac-types';
import ConfirmPurchaseDialog from 'datacosmos/components/ConfirmPurchase/ConfirmPurchaseDialog';
import type { StacAdapter } from 'datacosmos/services/stacAdapter';
import SidebarHeader from '_organisms/SidebarHeader/SidebarHeader';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { useCart } from 'datacosmos/stores/CartProvider';
import { useDisplayedStacItem } from 'datacosmos/utils/hooks/useDisplayedStacItem';
import { useMap } from 'datacosmos/stores/MapProvider';
import { ENABLE_UPGRADE_USER_ROLE } from 'env';
import UpgradeUserPermission from '_organisms/UpgradeUserPermission/UpgradeUserPermission';
import moment from 'moment';
import Accordion from 'opencosmos-ui/src/core/Accordion/Accordion';
import { useViewItem } from 'datacosmos/utils/hooks/useViewItem';
import type { PaymentMethod } from '_api/orders/types';
import { Button } from 'opencosmos-ui';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import config from 'datacosmos/config';
import { isBasicUser } from 'utils/auth/common';
import { useAuth } from 'services/auth/AuthWrapper';
import type { UserAssignments } from '_api/administration/types';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';

export const ResultsWrapper = ({
  title,
  isCatalog,
  stacAdapter,
}: {
  title: string;
  isCatalog: boolean;
  stacAdapter: StacAdapter;
}) => {
  const [isConfirmPurchaseOpen, setIsConfirmPurchaseOpen] = useState(false);
  const [isOpenUserUpgrade, setIsOpenUserUpgrade] = useState(false);
  const { user } = useAuth();

  const {
    getSearchResults,
    loadNextPage,
    hasMoreResults,
    isFetching,
    fetchedResultsContext,
    fetchingFailed,
    clearSearchResults,
  } = stacAdapter;

  const {
    currentScenario,
    handleAddToScenario,
    handleRemoveFromScenario,
    isItemInProject,
  } = useProjects();

  const { areFiltersApplied } = useFilters();

  const { translate } = useLocalisation();

  const { sendInfo } = useAnalytics();

  const searchResults = useMemo(() => getSearchResults(), [getSearchResults]);

  const {
    getResultsGroupedByProperty,
    // propertyToGroupBy,
    // setPropertyToGroupBy,
  } = useFeatureGroup(searchResults);
  const {
    displayOutline,
    removeOutline,
    removeLayersBySourceType,
    toggleAssetOnMap,
  } = useMapLayers();

  const { setViewToFitBbox } = useMap();

  const { setAutoViewToFitBboxIfNecessary } = useMapController();

  const {
    addToCart,
    clearCart,
    createOrderWithItemsInCart,
    cartItems,
    isInCart,
    setExternalPaymentId,
    externalPaymentId,
  } = useCart();

  const {
    isStacItemDisplayed,
    displayedStacLayers,
    isStacItemAssetDisplayed,
    removeLayersByStacItem,
  } = useDisplayedStacItem();

  const resultsGrouped = useMemo(
    () => getResultsGroupedByProperty(),
    [getResultsGroupedByProperty]
  );

  useEffect(() => {
    clearCart();
  }, [clearCart]);

  let messageTitle: string = translate(
    'datacosmos.catalogAndItems.errors.noResults.title'
  );
  let message: string = translate(
    'datacosmos.catalogAndItems.errors.noResults.description'
  );
  if (fetchingFailed) {
    messageTitle =
      fetchingFailed === 'forbidden'
        ? translate('datacosmos.catalogAndItems.errors.forbidden.title')
        : translate('datacosmos.catalogAndItems.errors.fetchingFailed.title');
    message =
      fetchingFailed === 'forbidden'
        ? translate('datacosmos.catalogAndItems.errors.forbidden.description')
        : translate(
            'datacosmos.catalogAndItems.errors.fetchingFailed.description'
          );
  }

  if (!fetchingFailed && areFiltersApplied) {
    message = translate(
      'datacosmos.catalogAndItems.errors.nothingMatchesFilters'
    );
  }

  const handleAddToProject = (item?: IStacItem) => {
    if (!currentScenario) {
      return;
    }
    if (item) {
      void handleAddToScenario(currentScenario, item);
      clearCart();
      return;
    }
    if (displayedStacLayers?.length) {
      displayedStacLayers.map((layer) =>
        handleAddToScenario(currentScenario, layer.item)
      );
      clearCart();
      return;
    }
  };

  const handleRemoveItemFromProject = (item?: StacItem) => {
    if (!currentScenario) {
      return;
    }
    if (item) {
      void handleRemoveFromScenario(currentScenario, item);
      return;
    }
    if (displayedStacLayers?.length) {
      displayedStacLayers.map((l) =>
        handleRemoveFromScenario(currentScenario, l.item)
      );
      return;
    }
  };

  const toggleFullResolutionImage = (result: StacItem, maxZoom?: number) => {
    const visualAssetKey = getVisualAssetKey(result);
    if (visualAssetKey) {
      toggleAssetOnMap(result, visualAssetKey);
      setAutoViewToFitBboxIfNecessary(result.bbox, maxZoom);
      if (result.collection)
        sendInfo({
          type: 'Full resolution image displayed',
          action: 'Select',
          item: `STAC item: id: ${result.id}, title: ${result?.title()}`,
          module: 'DataCosmos',
          dimensions: {
            dimension1: `${result.collection}/${result.id}`,
            dimension3: `${result.id}`,
          },
          additionalParams: {
            result,
          },
        });
    }
  };

  const icon = fetchingFailed ? IconNames.WARNING_SIGN : IconNames.SEARCH;

  useEffect(() => {
    if (isFetching) {
      removeLayersBySourceType(LayerSourceType.ASSET_OUTLINE);
    }
  }, [isFetching, removeLayersBySourceType, clearSearchResults]);

  const { hasPermission: isAllowedToUpgradeRoleForConida } =
    useCheckPermissions({
      permissions: {
        type: 'global',
        actionScope: 'data:order:create',
      },
    });

  const {
    isViewingSingleItem,
    isSingleStacItemNotAvailable,
    noMatchAvailableMessage,
  } = useViewItem(resultsGrouped);

  if (isSingleStacItemNotAvailable && noMatchAvailableMessage) {
    message = noMatchAvailableMessage;
  }

  const numberOfShownFeatures = Object.values(resultsGrouped).reduce(
    (prev, current) => {
      prev += current.length;
      return prev;
    },
    0
  );

  const resultsToDisplay = Object.keys(resultsGrouped).map((property) => {
    return (
      <Fragment key={`results-group-${property}`}>
        <div className="w-full relative bg-surface/50 dark:bg-surface-dark/50">
          <Accordion
            labelText={moment(property, 'DD/MM/YYYY').format('YYYY MMM DD')}
            startExpanded
            labelClassName="font-bold text-sm text-item-contrast dark:text-item-dark-contrast"
          >
            <div>
              {resultsGrouped[property].map((result) => {
                const isHighResPermissionGranted: boolean = result
                  ?.properties?.[
                  'opencosmos:high_resolution_read_permission'
                ]! as boolean;

                const maxZoom: number | undefined = !isHighResPermissionGranted
                  ? config.map.maxNativeZoomBasedOnReadPermission
                  : undefined;

                return (
                  <ResultsListCard
                    key={`stac-${result.collection ?? 'unknown'}-${result.id}`}
                    data={result}
                    isViewCatalog={isCatalog}
                    isItemDisplayed={isStacItemDisplayed(result)}
                    isAssetDisplayed={(assetID: string) =>
                      isStacItemAssetDisplayed(result, assetID)
                    }
                    isItemInCart={isInCart(result)}
                    isItemInProject={isItemInProject(result)}
                    onMouseOver={() => {
                      displayOutline(result);
                    }}
                    onMouseOut={() => {
                      removeOutline();
                    }}
                    handleToggleDisplayItem={(isHighRes: boolean) => {
                      if (isStacItemDisplayed(result)) {
                        removeLayersByStacItem(result);
                        return;
                      }

                      if (isHighRes) {
                        toggleFullResolutionImage(result, maxZoom);
                        return;
                      }
                      const key = getOverviewAssetKey(result as IStacItem);

                      if (hasOverviewRoleAsset(result) && key) {
                        toggleAssetOnMap(result, key);
                        setAutoViewToFitBboxIfNecessary(result.bbox, maxZoom);
                        removeOutline();
                        if (result.collection)
                          sendInfo({
                            type: 'Image overview displayed',
                            action: 'Select',
                            item: `STAC item: id: ${
                              result.id
                            }, title: ${result?.title()}`,
                            module: 'DataCosmos',
                            dimensions: {
                              dimension1: `${result.collection}/${result.id}`,
                              dimension3: `${result.id}`,
                            },
                            additionalParams: {
                              result,
                            },
                          });
                      }
                    }}
                    handleToggleDisplayAsset={(assetID: string) => {
                      toggleAssetOnMap(result, assetID);
                      setAutoViewToFitBboxIfNecessary(result.bbox, maxZoom);
                    }}
                    handleToggleCartClick={() => {
                      if (isInCart(result)) {
                        addToCart(result);
                        return;
                      }
                      if (isBasicUser(user?.assignments as UserAssignments[])) {
                        setIsOpenUserUpgrade(true);
                        return;
                      }
                      addToCart(result);
                    }}
                    handleToggleProjectClick={() => {
                      if (!isCatalog) {
                        handleRemoveItemFromProject(result);
                        return;
                      }

                      handleAddToProject(result);
                    }}
                    handleCenterMapClick={() => {
                      setViewToFitBbox(result.bbox, maxZoom);
                    }}
                  />
                );
              })}
            </div>
          </Accordion>
          <div className="absolute top-3 right-0 pr-5 font-bold text-inherit text-sm dark:text-neutral ">
            {resultsGrouped[property].length}{' '}
            {resultsGrouped[property].length > 1 ? `items` : `item`}
          </div>
        </div>
      </Fragment>
    );
  });

  return (
    <>
      <SidebarHeader title={title} />
      <header>
        <HomeBlurb
          isViewingSingleItem={
            isViewingSingleItem || isSingleStacItemNotAvailable
          }
        />
      </header>
      <div id="sidebar-body" className="overflow-x-hidden">
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => {
            if (isFetching) {
              return;
            }
            void loadNextPage();
          }}
          hasMore={hasMoreResults}
          useWindow={false}
          style={{ display: 'flex', flexFlow: 'column', height: '100%' }}
        >
          {resultsToDisplay.length > 0 ? (
            <ul
              className="results-list"
              data-testid="results-container"
              id="results"
            >
              {resultsToDisplay}
            </ul>
          ) : (
            !isFetching && (
              <NonIdealState
                description={message}
                icon={icon}
                title={
                  <span className="dark:bg-item-dark dark:text-item-dark-contrast">
                    {messageTitle}
                  </span>
                }
                className="dark:bg-item-dark dark:text-item-dark-contrast"
              />
            )
          )}
          {isFetching ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
                flexGrow: 1,
              }}
              data-testid="loading-indicator"
            >
              <Spinner size={100} />
            </div>
          ) : null}
        </InfiniteScroll>
      </div>
      <footer className="p-2 dark:bg-surface-dark dark:text-item-dark-contrast">
        {isCatalog && cartItems.length > 0 && !isConfirmPurchaseOpen && (
          <div className="p-1 w-full flex items-center justify-center">
            <Button
              data-testid="purchase-button"
              onPress={() => {
                if (
                  ENABLE_UPGRADE_USER_ROLE &&
                  !isAllowedToUpgradeRoleForConida
                ) {
                  setIsOpenUserUpgrade(true);
                  return;
                }
                setIsConfirmPurchaseOpen(true);
              }}
              className="w-11/12 p-3"
            >
              {translate('datacosmos.catalogAndItems.buyFullResImages')}
            </Button>
          </div>
        )}

        <div className="flex justify-between">
          {fetchedResultsContext?.matched !== undefined ? (
            <div className="flex flex-col text-center">
              <span>
                {translate('datacosmos.catalogAndItems.amountAvailable', {
                  amount: numberOfShownFeatures,
                  total:
                    isViewingSingleItem || isSingleStacItemNotAvailable
                      ? Object.keys(resultsGrouped).length
                      : fetchedResultsContext.matched ?? '?',
                })}
              </span>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </footer>

      {isCatalog && (
        <ConfirmPurchaseDialog
          createOrderWithItemsInCart={(paymentMethod: PaymentMethod) => {
            sendInfo({
              type: 'Images purchased',
              action: 'Purchase',
              item: `Cart images`,
              module: 'DataCosmos',
              additionalParams: cartItems,
            });

            if (!currentScenario?.summaries) {
              return undefined;
            }
            if (
              currentScenario?.summaries &&
              !currentScenario?.summaries['opencosmos:organisation_id']
            ) {
              return undefined;
            }

            return createOrderWithItemsInCart(
              paymentMethod,
              Number(currentScenario?.summaries['opencosmos:organisation_id'])
            );
          }}
          imagesToPurchase={cartItems}
          isOpen={isConfirmPurchaseOpen}
          setIsOpen={setIsConfirmPurchaseOpen}
          setExternalPaymentId={setExternalPaymentId}
          externalPaymentId={externalPaymentId}
        />
      )}

      {isOpenUserUpgrade && (
        <UpgradeUserPermission
          text={translate('datacosmos.header.upgradePermission')}
          openModalByDefault={true}
          closeModal={setIsOpenUserUpgrade}
        />
      )}
    </>
  );
};
