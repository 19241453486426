import { filter, toNumber } from 'lodash/fp';
import { IconNames } from '@blueprintjs/icons';
import { useParams } from 'react-router-dom';
import AdministrationHeader from 'pages/portal/shared/components/AdministrationHeader';
import AdministrationUsers from 'pages/portal/Administration/User/components/AdministrationUsers/AdministrationUsers';
import useResource from 'pages/portal/shared/hooks/useResource';
import AdministrationLayout from '../../shared/components/AdministrationLayout/AdministrationLayout';
import ResourcePermissionsManager from 'pages/portal/shared/components/PermissionManager/ResourcePermissionsManager';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import AdministrationProgrammeList from './components/AdministrationProgrammeList';
import AdministrationOrganisationDetails from './components/AdministrationOrganisationDetails';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { usePortalService } from 'services/api/portal/organisation/usePortalService';
import { useAuth } from 'services/auth/AuthWrapper';

const OrganisationAdministration = () => {
  const { user } = useAuth();
  const { translate } = useLocalisation();
  const { organisation } = useParams<{ organisation: string }>();

  const {
    organisationsList: organisations,
    programmesList: programmes,
    getOrganisations,
  } = usePortalService(user ? user.sub : '');
  const organisationId = toNumber(organisation);

  const {
    availableRoles,
    resourceRoleAssignments,
    deleteRoleAssignment,
    assignRole,
    resourceRoleAttachments,
    getRoleAttachmentsByResourceTypeAndId,
  } = useResource('organisation', organisationId);

  const organisationObj = organisations.find(
    (org) => org.id === organisationId
  );
  const organisationName = organisationObj?.name!;

  const { hasPermission: isAllowedToManageBilling } = useCheckPermissions({
    permissions: {
      type: 'organisation',
      actionScope: 'organisation:billing-details:write',
      id: organisationId,
    },
  });

  return (
    <AdministrationLayout
      breadcrumbs={[
        { text: translate('datacosmos.header.menu.organisations') },
        { text: organisationName },
      ]}
      showEmptyData={!organisationName}
      emptyDataIcon={IconNames.OFFICE}
      emptyDataTitle={translate('organisationsPage.searchPlaceholder', {
        organisationId,
      })}
      graphic="operations"
    >
      <AdministrationHeader
        id={organisationId}
        title={organisationName}
        icon={IconNames.OFFICE}
      />
      <AdministrationOrganisationDetails
        organisation={organisationObj}
        disableBilling={!isAllowedToManageBilling}
        refetchOrganisation={() => {
          void getOrganisations();
        }}
      />
      <hr className="mt-3 border-t-[1px] border-accent-700 dark:border-item-dark-hover" />
      <AdministrationProgrammeList
        programmesList={filter(['organisationId', organisationId], programmes)}
      />
      <hr className="mt-3 border-t-[1px] border-accent-700 dark:border-item-dark-hover" />
      <AdministrationUsers
        currentId={organisationId}
        assignRole={assignRole}
        deleteRoleAssignments={(
          userId: string,
          assignmentIds: number[],
          showDeletedMessage?: boolean
        ) => deleteRoleAssignment(assignmentIds, showDeletedMessage)}
        availableRoles={availableRoles}
        roleAssignments={resourceRoleAssignments}
      />
      <hr className="my-5 border-t-[1px] border-accent-700 dark:border-item-dark-hover" />
      {resourceRoleAttachments && (
        <ResourcePermissionsManager
          resourceAttachments={resourceRoleAttachments}
          resourceType="organisation"
          resourceId={organisationId.toString()}
          getAttachmentsCallback={() => getRoleAttachmentsByResourceTypeAndId()}
          availableRoles={availableRoles}
        />
      )}
    </AdministrationLayout>
  );
};

export default OrganisationAdministration;
