import { Dialog } from '@blueprintjs/core';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap, type IMapContext } from 'datacosmos/stores/MapProvider';
import useMapCoordinates from 'datacosmos/utils/hooks/useMapCoordinates';
import { TERMS_AND_CONDITIONS_ENABLE } from 'env';
import { uniq } from 'lodash/fp';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BottomColorBarsList from './BottomColorBarsList';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { useTimeSeries } from 'datacosmos/stores/TimeSeriesProvider';
import TimeSeriesSlider from '_organisms/TimeSeriesSlider/TimeSeriesSlider';
import moment from 'moment';
import type { EPSG } from 'datacosmos/utils/coordinates/epsgCoordinates';
import {
  EPSG4326,
  EPSG3857,
} from 'datacosmos/utils/coordinates/epsgCoordinates';
import {
  convertToEPSG32630,
  convertToEPSG3857,
  convertToEPSG4326,
} from 'datacosmos/utils/coordinates/coordinateConverter';
import BottomBarScale from './BottomBarScale';
import { Icon, Tooltip } from 'opencosmos-ui';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { zIndexValues } from 'opencosmos-ui/constants';
import { parseCopyright } from 'utils/common/CommonUtils';

type BottomBarProps = {
  map: IMapContext | null;
  isMinimapExpanded: boolean;
};

const BottomBar = ({ isMinimapExpanded, map }: BottomBarProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCopyrightOpen, setIsCopyrightOpen] = useState<boolean>(false);

  const { layers } = useMapLayers();

  const [copyright, setCopyright] = useState<string[]>([]);

  const { latLng } = useMapCoordinates(map);
  const { sendInfo } = useAnalytics();

  const { mapRef } = useMap();
  const { translate } = useLocalisation();

  const [selectedCoordinateSystem, setSelectedCoordinateSystem] =
    useState<EPSG>(EPSG4326);

  const {
    minMaxDates,
    dateToShow,
    setDateToShow,
    isTimeSeriesToggled,
    hideLayersAfterDate,
    timeSeriesLayers,
  } = useTimeSeries();

  const stacLayer = useMemo(() => {
    return layers.filter(
      (l) =>
        l instanceof SingleBandSTACLayer || l instanceof BandAlgebraSTACLayer
    ) as (SingleBandSTACLayer | BandAlgebraSTACLayer)[];
  }, [layers]);

  useEffect(() => {
    if (stacLayer.length === 0) {
      setCopyright([]);
      return;
    }

    setCopyright(() =>
      uniq(
        stacLayer
          .map((l) => l.item.properties['opencosmos:copyright'])
          .filter((c) => c !== undefined)
          .map((c) => c + '  ')
      )
    );
  }, [stacLayer]);

  /**
   * @returns the terms and conditions element for DataCosmos, if configured
   */
  const termsAndConditions = () => {
    if (!TERMS_AND_CONDITIONS_ENABLE) {
      return null;
    }
    return (
      <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <a
          onClick={() => {
            sendInfo({
              type: 'Terms and conditions open',
              action: 'Click',
              item: 'Terms and conditions button',
              module: 'DataCosmos',
            });

            setIsOpen(true);
          }}
          className="dark:!text-item-dark-contrast !text-item-contrast"
        >
          {copyright.length > 0 && '|'} Terms & conditions
        </a>
      </span>
    );
  };

  const toggleBetweenCoordinateSystems = useCallback(() => {
    if (selectedCoordinateSystem.code === 'EPSG:4326') {
      setSelectedCoordinateSystem(EPSG3857);
    }

    setSelectedCoordinateSystem(EPSG4326);
  }, [selectedCoordinateSystem.code]);

  const displayedLatLng = useMemo(() => {
    const latLngArr = latLng?.split(',').map((c) => parseFloat(c));
    if (!latLng) {
      return '';
    }

    if (selectedCoordinateSystem.code === 'EPSG:4326') {
      return convertToEPSG4326(
        [{ lat: latLngArr[1], lng: latLngArr[0] }],
        EPSG4326
      )
        .map((n) => n.toFixed(6))
        .join(', ');
    }

    if (selectedCoordinateSystem.code === 'EPSG:32630') {
      return convertToEPSG32630(
        [{ lat: latLngArr[1], lng: latLngArr[0] }],
        EPSG4326
      )
        .map((n) => n.toFixed(6))
        .join(', ');
    }

    if (selectedCoordinateSystem.code === 'EPSG:3857') {
      return convertToEPSG3857(
        [{ lat: latLngArr[1], lng: latLngArr[0] }],
        EPSG4326
      )
        .map((n) => n.toFixed(6))
        .join(', ');
    }

    return latLng;
  }, [latLng, selectedCoordinateSystem.code]);

  return (
    <div
      id="bottom-bar"
      className="w-full flex flex-col gap-2 bg-surface dark:bg-surface-dark dark:text-item-dark-contrast text-sm p-2"
      // Disable map dragging when hovering over the bottom bar
      onMouseOver={() => {
        mapRef.current?.dragging.disable();
      }}
      // Enable map dragging when not hovering over the bottom bar
      onMouseOut={() => {
        mapRef.current?.dragging.enable();
      }}
      style={{
        zIndex: zIndexValues.map,
      }}
    >
      <TimeSeriesSlider
        maxDate={minMaxDates.maxDate}
        minDate={minMaxDates.minDate}
        selectedDate={
          dateToShow instanceof Date && !isNaN(dateToShow.valueOf())
            ? dateToShow
            : minMaxDates.minDate
        }
        marks={timeSeriesLayers.map((l) =>
          moment(l.item.properties.datetime).unix()
        )}
        isShown={isTimeSeriesToggled}
        onChange={(date) => {
          setDateToShow(moment.unix(date[0]).toDate());
          hideLayersAfterDate(moment.unix(date[0]).toDate());
        }}
      />
      <BottomColorBarsList isMinimapExpanded={isMinimapExpanded} />
      <div className="flex items-center justify-between w-full gap-2">
        <div className="scale w-full flex gap-3">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => toggleBetweenCoordinateSystems()}
          >
            <div className="flex items-center">
              {selectedCoordinateSystem.code}
              <Tooltip
                content={translate(
                  'datacosmos.bottomBar.selectedCoordinateSystem'
                )}
                placement="top"
              >
                <Icon icon="Info" size={12} className="flex self-start" />
              </Tooltip>
            </div>
            <div className="flex items-center">
              {displayedLatLng}
              <Tooltip
                content={translate('datacosmos.bottomBar.coordinates')}
                placement="top"
              >
                <Icon icon="Info" size={12} className="flex self-start" />
              </Tooltip>
            </div>
          </div>

          <BottomBarScale map={map?.scale} />
        </div>

        <div className="text-xs flex justify-end w-full overflow-hidden">
          {copyright.length > 0 && (
            <span
              className="mr-1"
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
              }}
              onClick={() => {
                sendInfo({
                  type: 'Copyrights open',
                  action: 'Click',
                  item: 'Copyrights button',
                  module: 'DataCosmos',
                });
                setIsCopyrightOpen(true);
              }}
            >
              {parseCopyright(copyright).join(' · ')}
            </span>
          )}
          {termsAndConditions()}
        </div>
      </div>

      <Dialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        canOutsideClickClose
        className="dark:bg-surface-dark dark:text-item-dark-contrast bg-surface"
      >
        <div style={{ height: '40vh', padding: '10px', overflowY: 'auto' }}>
          <div style={{ marginBottom: '20px' }}>
            <span style={{ fontSize: '22px' }}>Terms and conditions</span>
          </div>
          <p>
            <a href="">Please see the OpenApp terms and conditions</a>
          </p>
        </div>
      </Dialog>

      <Dialog
        isOpen={isCopyrightOpen}
        onClose={() => setIsCopyrightOpen(false)}
        canOutsideClickClose
        className="dark:bg-surface-dark dark:text-item-dark-contrast bg-surface"
      >
        <div
          style={{ height: '40vh', padding: '10px', overflowY: 'auto' }}
          className="dark:bg-surface-dark dark:text-item-dark-contrast bg-surface"
        >
          <div style={{ marginBottom: '20px' }}>
            <span style={{ fontSize: '22px' }}>Copyright</span>
          </div>
          {parseCopyright(copyright).map((text) => (
            <p key={text}>{text}</p>
          ))}
        </div>
      </Dialog>
    </div>
  );
};

export default BottomBar;
