import { createBrowserHistory } from 'history';
import { MODE } from 'env';
import configureStore from 'store';

// TODO: Make it true by default after all effect dependencies are fixed
export const ENABLE_STRICT_MODE: boolean = MODE === 'development' && false;

// import { setUseWhatChange } from '@simbathesailor/use-what-changed';
// setUseWhatChange(MODE === 'development');

// Usage:
// import { useWhatChanged } from '@simbathesailor/use-what-changed';
// useWhatChanged([a, b, b], 'a, b, c', 'suffix');

export const history = createBrowserHistory({
  basename: '/',
});

export const store = configureStore();
