import { Intent, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import type { IMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import type { Geometry, Polygon } from 'geojson';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { toaster } from 'toaster';
import type { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { PolygonLayerFactory } from 'datacosmos/entities/polygonLayer';
import area from '@turf/area';
import { default as CustomBtn } from '_molecules/Button/Button';

import {
  geojsonToGeoJson,
  gpkgToGeoJson,
  kmlToGeoJson,
  shapefileToGeoJson,
} from 'datacosmos/utils/fileToGeojson';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import JSZip from 'jszip';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { Tooltip, Icon } from 'opencosmos-ui';

interface IProps {
  buttonTitle: string;
  aoiSourceType: LayerSourceType;
  areaOfInterest?: Polygon[];
  setAreaOfInterest: React.Dispatch<
    React.SetStateAction<Polygon[] | undefined>
  >;
  buttonForApplications?: true;
  buttonForMenu?: true;
  multipleAois?: true;
  disableButton?: boolean;
  onPressExtraAction?: () => void;
  disableUploadValidation?: boolean;
  mapLayersProvider?: IMapLayers;
  showUploadingSpinner?: boolean;
  onAddLayer?: (layer: PolygonLayer) => void;
}

const getInfoToast = (message: string) => ({
  intent: Intent.NONE,
  icon: IconNames.INFO_SIGN,
  message,
});

const getDangerToast = (message: string) => ({
  message,
  icon: IconNames.ERROR,
  intent: Intent.DANGER,
});

const UploadRegion = ({
  buttonForApplications,
  areaOfInterest,
  setAreaOfInterest,
  aoiSourceType,
  buttonTitle,
  multipleAois,
  disableButton,
  onPressExtraAction,
  disableUploadValidation,
  buttonForMenu,
  mapLayersProvider,
  showUploadingSpinner,
  onAddLayer,
}: IProps) => {
  let mapLayers = useMapLayers();

  if (!mapLayers && mapLayersProvider) {
    mapLayers = mapLayersProvider;
  }

  const { addLayer, layers, removeLayersBySourceType } = mapLayers;

  const inputFileRef = useRef<HTMLInputElement>(null);

  const { translate } = useLocalisation();

  const { sendInfo } = useAnalytics();

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleAddFeatureToMap = (geo: GeoJSON.Feature) => {
    const m2 = area(geo);
    //TODO: Remove this toast since we no longer use geodesic
    if (geo.geometry.type === 'GeometryCollection') {
      toaster.show(
        getInfoToast(
          'Geodesic lines cannot be shown for GeometryCollections, lines will appear straight'
        )
      );
    }

    const layer = PolygonLayerFactory(
      aoiSourceType,
      translate('datacosmos.layers.names.aoi'),
      geo,

      m2,
      null,
      {
        color: '#e4695e',
      }
    );

    addLayer(layer);
    onAddLayer?.(layer);

    if (!multipleAois) {
      setAreaOfInterest([geo.geometry as GeoJSON.Polygon]);
    } else {
      setAreaOfInterest((prev) => [
        ...(prev ?? []),
        geo.geometry as GeoJSON.Polygon,
      ]);
    }
  };

  const hasMultipleFeatures = (
    geo: GeoJSON.FeatureCollection<Geometry | null>
  ) => {
    if (geo.features?.length > 1 && !multipleAois) {
      toaster.show(
        getDangerToast(
          translate('datacosmos.filters.errors.noFilteringByMultiple')
        )
      );
      return true;
    }
    return false;
  };

  const isAlreadyOnMap = (geo: GeoJSON.FeatureCollection<Geometry | null>) => {
    if (isRegionAlreadyUploaded(geo)) {
      toaster.show(
        getDangerToast(translate('datacosmos.uploadRegion.fileAlreadyPresent'))
      );
      return true;
    }
    return false;
  };

  const handleAddRegionToMap = (
    geoJson: GeoJSON.Feature | GeoJSON.FeatureCollection<Geometry | null>
  ) => {
    !multipleAois && removeLayersBySourceType(aoiSourceType);

    if ((geoJson as GeoJSON.FeatureCollection).features) {
      (geoJson as GeoJSON.FeatureCollection).features.map((geo) =>
        handleAddFeatureToMap(geo)
      );
    } else {
      handleAddFeatureToMap(geoJson as GeoJSON.Feature);
    }
  };

  const handleGeoJsonFiles = (fileReader: FileReader) => {
    let geoJson: GeoJSON.FeatureCollection<Geometry | null>;

    try {
      geoJson = geojsonToGeoJson(fileReader.result, {
        disableValidation: disableUploadValidation,
      });
    } catch (err) {
      toaster.show({
        message: (err as { message: string }).message,
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
      });
      return;
    }

    const hasMultiple = hasMultipleFeatures(geoJson);
    const isOnMap = isAlreadyOnMap(geoJson);

    if (hasMultiple || isOnMap) {
      return;
    } else {
      handleAddRegionToMap(geoJson);
    }
  };

  const handleKmlFiles = (fileReader: FileReader, file?: string) => {
    let geoJson: GeoJSON.FeatureCollection<Geometry | null>;

    try {
      geoJson = kmlToGeoJson(file ? file : fileReader.result, {
        disableValidation: disableUploadValidation,
      });
    } catch (err) {
      toaster.show({
        message: (err as { message: string }).message,
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
      });
      return;
    }

    const hasMultiple = hasMultipleFeatures(geoJson);
    const isOnMap = isAlreadyOnMap(geoJson);

    if (hasMultiple || isOnMap) {
      return;
    } else {
      handleAddRegionToMap(geoJson);
    }
  };

  const handleGeopackageFiles = async (fileReader: FileReader) => {
    let featCol: GeoJSON.FeatureCollection<Geometry | null>;

    try {
      featCol = await gpkgToGeoJson(fileReader.result, {
        disableValidation: disableUploadValidation,
      });
    } catch (error) {
      toaster.show({
        message: (error as { message: string }).message,
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
      });
      return;
    }

    const hasMultiple = hasMultipleFeatures(featCol);
    const isOnMap = isAlreadyOnMap(featCol);

    if (hasMultiple || isOnMap) {
      return;
    } else {
      handleAddRegionToMap(featCol);
    }
  };

  const handleKMZFiles = async (fileReader: FileReader) => {
    if (!fileReader) return;

    const zip = new JSZip();
    const zipData = await zip.loadAsync(fileReader.result as ArrayBuffer);

    // Assuming there's only one KML file inside the KMZ
    const kmlFileName = Object.keys(zipData.files)[0];
    const kmlText = await zipData.files[kmlFileName].async('text');
    handleKmlFiles(fileReader, kmlText);
  };

  const handleShapefileFiles = async (fileReader: FileReader) => {
    let featCol: GeoJSON.FeatureCollection<Geometry | null>;

    try {
      featCol = await shapefileToGeoJson(fileReader.result, {
        disableValidation: disableUploadValidation,
      });
    } catch (error) {
      toaster.show({
        message: (error as { message: string }).message,
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
      });
      return;
    }

    const hasMultiple = hasMultipleFeatures(featCol);
    const isOnMap = isAlreadyOnMap(featCol);

    if (hasMultiple || isOnMap) {
      return;
    } else {
      handleAddRegionToMap(featCol);
    }
  };

  /**
   * Main entry point for handling area of interest loading in a single area of interest file.
   * Should delegate handling specific file types to sub-functions.
   * This top level function controls the associated input box
   * (e.g. for clearing the input) and none of the functions it calls
   * should have this responsibility.
   * @param fileReader for reading an uploaded file
   * @param fileExtension uploaded file's exteension
   */
  const handleFileLoad = async (
    fileReader: FileReader,
    fileExtension: string
  ) => {
    try {
      if (fileExtension === 'geojson') {
        handleGeoJsonFiles(fileReader);
      } else if (fileExtension === 'kml') {
        handleKmlFiles(fileReader);
      } else if (fileExtension === 'gpkg') {
        await handleGeopackageFiles(fileReader);
      } else if (fileExtension === 'shp') {
        await handleShapefileFiles(fileReader);
      } else if (fileExtension === 'kmz') {
        await handleKMZFiles(fileReader);
      }
    } finally {
      // onPressExtraAction can be called here since handle file load is called when upload button is pressed
      onPressExtraAction?.();
      if (inputFileRef.current) {
        inputFileRef.current.value = '';
      }
    }
  };

  /**
   * Extracts from the file list, if available, the list of files, else returns an empty list
   * @param fl from which to extract all files
   * @returns files from list if available, else empty list
   */
  const fromList = (fl: FileList | null): File[] => {
    if (fl === null) {
      return [];
    }
    return [...fl];
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    const files = fromList(e.target.files);

    files.map((file) => {
      const reader = new FileReader();
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1);

      if (extension === 'geojson' || extension === 'kml') {
        reader.readAsText(file);
      }

      if (extension === 'gpkg') {
        reader.readAsArrayBuffer(file);
      }

      if (extension === 'shp') {
        reader.readAsArrayBuffer(file);
      }
      if (extension === 'kmz') {
        reader.readAsArrayBuffer(file);
      }

      reader.onloadend = () => {
        void handleFileLoad(reader, extension);
      };
    });
    setIsUploading(false);
  };

  const isRegionAlreadyUploaded = (
    geoJson: GeoJSON.FeatureCollection<Geometry | null>
  ) => {
    return (
      (layers as GeoJSONLayer<unknown>[]).filter((l) =>
        geoJson.features.some((f) => isEqual(f, l.data))
      ).length > 0
    );
  };

  const shouldClearAreaOfInterest =
    (areaOfInterest ? areaOfInterest.length > 0 : true) &&
    !layers.some((layer) => layer.sourceType === aoiSourceType) &&
    !multipleAois;

  const renderButton = () => {
    if (buttonForApplications) {
      return (
        <CustomBtn
          text={
            showUploadingSpinner && isUploading ? (
              <Spinner size={16} />
            ) : (
              buttonTitle
            )
          }
          icon="Upload"
          size={24}
          className="bg-item h-8 w-full text-start border-2 border-item dark:bg-item-dark dark:text-item-dark-contrast dark:hover:text-item-dark-hover"
          disabled={Boolean(disableButton)}
          onPress={() => {
            inputFileRef.current?.click();
          }}
        />
      );
    }

    if (buttonForMenu) {
      return (
        <div className="flex items-center justify-center pl-2 pt-1 dark:hover:bg-data-light-text-field-stroke hover:bg-data-dark-text-field-stroke hover:bg-opacity-10 dark:hover:bg-opacity-20">
          <Icon
            className="stroke-item-contrast dark:stroke-item-dark-contrast"
            icon="Upload"
          />
          <CustomBtn
            text={
              showUploadingSpinner && isUploading ? (
                <Spinner size={16} />
              ) : (
                buttonTitle
              )
            }
            size={24}
            className="h-6 m-0 !p-0 bg-transparent dark:bg-transparent dark:hover:text-data-dark-hover hover:text-data-light-hover"
            disabled={Boolean(disableButton)}
            onPress={() => {
              inputFileRef.current?.click();
            }}
          />
        </div>
      );
    }

    return (
      <CustomBtn
        text={
          showUploadingSpinner && isUploading ? (
            <Spinner size={16} />
          ) : (
            buttonTitle
          )
        }
        icon="Upload"
        size={24}
        className="bg-item h-8 w-full text-start border-2 border-item dark:bg-item-dark dark:text-item-dark-contrast dark:hover:text-item-dark-hover"
        disabled={Boolean(disableButton)}
        onPress={() => {
          inputFileRef.current?.click();
        }}
      />
    );
  };

  useEffect(() => {
    if (shouldClearAreaOfInterest) {
      setAreaOfInterest([]);
    }
  }, [setAreaOfInterest, shouldClearAreaOfInterest]);

  return (
    <>
      <input
        aria-label="input-region-upload"
        type="file"
        name=""
        id=""
        accept={AOI_UPLOAD_ACCEPTED_FILE_TYPES}
        ref={inputFileRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          sendInfo({
            action: 'Region upload',
            item: 'Region upload button',
            type: 'Upload',
            module: 'DataCosmos',
            additionalParams: {
              files: JSON.stringify(e.target.files),
              extensions: fromList(e.target.files).map(
                (file) => file.name.split('.')[1]
              ),
            },
          });
          handleFileUpload(e);
        }}
        multiple={multipleAois}
      />
      <div className="uploadAoiContainer">
        <Tooltip
          content={translate(`datacosmos.uploadRegion.filesSupported`, {
            files: AOI_UPLOAD_ACCEPTED_FILE_TYPES,
          })}
          placement="right"
          isDisabled={disableButton}
        >
          {renderButton()}
        </Tooltip>
      </div>
    </>
  );
};

export default UploadRegion;

/**
 * File types accepted for upload, including the dot
 * and as a comma separated list with a space between each entry
 */
const AOI_UPLOAD_ACCEPTED_FILE_TYPES = '.kml, .geojson, .gpkg, .shp, .kmz';
