import { useAuth } from 'services/auth/AuthWrapper';
import organisationApi from 'services/api/portal/organisation/organisationApi';
import programmeApi from 'services/api/portal/organisation/programmeApi';
import { getOrganisations, getProgrammes } from '_api/administration/service';
import { useQuery } from '_api/useQuery';
import { BACKEND_BASE_URL } from 'env';

const PORTAL_URL = `${BACKEND_BASE_URL}/portal/v0`;

export const usePortalService = (userId = '') => {
  const getOrganisationsQuery = useQuery(getOrganisations, {
    initialData: [],
    params: { userId },
    skip: !userId,
  });
  const getProgrammesQuery = useQuery(getProgrammes, {
    initialData: [],
    params: { userId },
    skip: !userId,
  });

  const { token } = useAuth();
  const organisationApiInstance = organisationApi(PORTAL_URL, token);
  const programmeApiInstance = programmeApi(PORTAL_URL, token);

  const postOrganisations = async (name: string) => {
    await organisationApiInstance.postOrganisation(name);
    void getOrganisationsQuery.refetch();
  };

  const postProgrammes = async (name: string, organisationId: number) => {
    await programmeApiInstance.postProgramme(name, organisationId);
    void getProgrammesQuery.refetch();
  };

  return {
    organisationsList: getOrganisationsQuery.data,
    getOrganisations: getOrganisationsQuery.refetch,
    programmesList: getProgrammesQuery.data,
    getProgrammes: getProgrammesQuery.refetch,
    postOrganisations,
    postProgrammes,
  };
};
