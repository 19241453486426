import type {
  Organisation,
  OrganisationJoinPolicy,
} from '_api/administration/types';
import { useQuery } from '_api/useQuery';
import {
  getOrganisationsUserCanJoin,
  addUserToOrganisation,
} from '_api/organisations/service';
import { Button } from 'opencosmos-ui';
import { useState } from 'react';
import { useAuth } from 'services/auth/AuthWrapper';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { postOrganisation } from '_api/administration/service';

interface JoinOrCreateOrganisationProps {
  handleCreate: () => void;
  handleContinueAfterAddingUser: () => void;
  automaticContinue?: boolean;
}

const JoinOrCreateOrganisation = ({
  handleCreate,
  handleContinueAfterAddingUser,
  automaticContinue,
}: JoinOrCreateOrganisationProps) => {
  const { translate } = useLocalisation();
  const { user } = useAuth();
  const [addedUserToOrganisation, setAddedUserToOrganisation] =
    useState<boolean>(false);

  const [addingUserToOrganisation, setIsAddingUserToOrganisation] = useState<
    number | undefined
  >(undefined);

  const [orgsUserJoined, setOrgsUserJoined] = useState<number[]>([]);

  const [isAddingAsPersonal, setIsAddingAsPersonal] = useState<boolean>(false);

  const getJoinPolicyButtonText = (policy: OrganisationJoinPolicy) => {
    if (policy === 'INVITE') {
      return translate('onboarding.continueToPlatform.acceptInvite');
    }
    if (policy === 'REQUEST') {
      return translate('onboarding.continueToPlatform.requestBtn');
    }
    return translate('onboarding.continueToPlatform.joinBtn');
  };

  const handleOrgAction = async (selectedOrg: Organisation) => {
    if (selectedOrg?.join_policy === 'JOIN') {
      if (!user) {
        return;
      }

      setIsAddingUserToOrganisation(selectedOrg?.id);
      const { success } = await addUserToOrganisation({
        params: {
          organisationId: selectedOrg?.id?.toString(),
        },
      });
      setIsAddingUserToOrganisation(undefined);
      if (success && automaticContinue) {
        handleContinueAfterAddingUser();
      }
      if (success) {
        setAddedUserToOrganisation(true);
        setOrgsUserJoined((prevOrgs) => [...prevOrgs, selectedOrg?.id]);
      }
    }
  };

  const { data: organisationsToJoin } = useQuery(getOrganisationsUserCanJoin, {
    initialData: undefined,
  });

  const handleUseAsPersonalAccount = async () => {
    if (!user) {
      return;
    }
    setIsAddingAsPersonal(true);
    const { success } = await postOrganisation({
      body: {
        name: `${user.name} Personal`,
        join_policy: 'INDIVIDUAL',
      },
    });
    if (success) {
      handleContinueAfterAddingUser();
    }
    setIsAddingAsPersonal(false);
  };

  return (
    <>
      <h4 className="font-bold text-lg mb-4">
        {addedUserToOrganisation
          ? translate('onboarding.createOrganisation.titleJoin')
          : translate('onboarding.createOrganisation.title')}
      </h4>

      {organisationsToJoin && organisationsToJoin?.length > 0 ? (
        <>
          <p className="mb-2 text-sm">
            {translate('onboarding.createOrganisation.subtitle', {
              count: organisationsToJoin?.length,
            })}
          </p>
          <div className="max-h-36 xl:max-h-52 overflow-y-auto flex flex-col gap-1">
            {organisationsToJoin?.map((org) => (
              <div
                key={org.id}
                className="flex justify-between items-center bg-neutral dark:bg-neutral-dark p-2"
              >
                <h4>{org?.name}</h4>
                <Button
                  intent="primary"
                  className={
                    'w-26 text-item-accent-contrast hover:text-item-contrast'
                  }
                  onPress={() => handleOrgAction(org)}
                  loading={addingUserToOrganisation === org?.id}
                  isDisabled={
                    Boolean(addingUserToOrganisation) ||
                    orgsUserJoined.includes(org?.id) ||
                    // TODO: These join methods are not implemented yet
                    org?.join_policy === 'INVITE' ||
                    org?.join_policy === 'REQUEST'
                  }
                >
                  {orgsUserJoined.includes(org?.id)
                    ? translate('onboarding.continueToPlatform.joined')
                    : getJoinPolicyButtonText(org?.join_policy)}
                </Button>
              </div>
            ))}
          </div>
        </>
      ) : null}

      <p className="mt-6 text-xs">
        {translate('onboarding.createOrganisation.description')}
      </p>
      {addedUserToOrganisation ? (
        <Button
          intent="primary"
          size="base"
          onPress={handleContinueAfterAddingUser}
          className={
            'mt-10 text-item-accent-contrast hover:text-item-contrast float-right'
          }
        >
          {translate('onboarding.continueToPlatform.buttonPrompt')}
        </Button>
      ) : (
        <div className="flex flex-col gap-2">
          <Button
            intent="primary"
            size="base"
            onPress={handleCreate}
            className={
              'mt-10 text-item-accent-contrast hover:text-item-contrast'
            }
          >
            {translate('onboarding.createOrganisation.buttonPrompt')}
          </Button>
          <Button
            intent="primary"
            size="base"
            onPress={handleUseAsPersonalAccount}
            className={
              'mt-3 text-item-accent-contrast hover:text-item-contrast'
            }
            loading={isAddingAsPersonal}
          >
            {translate('onboarding.createOrganisation.personalButtonPrompt')}
          </Button>
        </div>
      )}
    </>
  );
};

export default JoinOrCreateOrganisation;
