import React, { useCallback, useState } from 'react';

import conidaLogo from 'public/images/datacosmos/conida-logo.png';
import type { IAPIAppValue, IApplication } from 'datacosmos/types/applications';
import { btoaSafe } from 'utils/common/btoaSafe';
import { clientTranslate, useLocalisation } from 'utils/hooks/useLocalisation';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import OpenedAppCard from '../SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from '../SubscriptionApps/Common/UnopenedAppCard';
import { Icon, Input, ListBoxItem, Select } from 'opencosmos-ui';
import { useClickedStacItem } from 'datacosmos/utils/hooks/useClickedStacItem';
import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import type { StacItem } from 'datacosmos/types/stac-types';
import { isGeoreferencedWithGCP } from 'datacosmos/utils/stac';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import { submitWorkflow } from '_api/pdgs/service';
import InputFileButton from 'components/buttons/InputFileButton';
import { putStorageFile } from '_api/storage/service';
import { useMutation } from '@tanstack/react-query';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import { useAuth } from 'services/auth/AuthWrapper';
import { v1 as uuid } from 'uuid';
import { hosts } from '_api/hosts';

type Props = {
  app: IApplication;
};

const array0to6 = Array.from({ length: 7 }, (_e, i) => i.toString());
const array0to12 = Array.from({ length: 13 }, (_e, i) => i.toString());

const OPTIONS = [
  { key: 'asp_bm', subpixelModes: array0to6, defaultSubpixelMode: '1' },
  { key: 'asp_mgm', subpixelModes: array0to12, defaultSubpixelMode: '12' },
  { key: 'asp_sgm', subpixelModes: array0to12, defaultSubpixelMode: '12' },
  {
    key: 'asp_final_mgm',
    subpixelModes: array0to12,
    defaultSubpixelMode: '12',
  },
] as const;

export const DEMGenerationApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name +
          JSON.stringify(this.provider) +
          this.description +
          this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: clientTranslate('datacosmos.applications.generate-dem.title'),
  description: clientTranslate(
    'datacosmos.applications.generate-dem.description'
  ),
  inputs: [
    {
      field: 'leftImage',
      example: '',
    },
    {
      field: 'rightImage',
      example: '',
    },
    {
      field: 'stereoAlgorithm',
      example: '',
    },
    {
      field: 'subpixelMode',
      example: '',
    },
    {
      field: 'demReference',
      example: '',
    },
  ],
  values: {
    leftImage: { value: '', isError: false, message: '' },
    rightImage: { value: '', isError: false, message: '' },
    stereoAlgorithm: { value: '', isError: false, message: '' },
    subpixelMode: { value: '', isError: false, message: '' },
    demReference: { value: '', isError: false, message: '' },
  },
  provider: {
    id: 1,
    // If the name is blank, the logo will be full width.
    name: '',
    description:
      'Comisión Nacional de Investigación y Desarrollo Aeroespacial del Perú',
    url: 'https://www.gob.pe/conida',
    icon_url: conidaLogo,
  },
  shortDescription: clientTranslate(
    'datacosmos.applications.generate-dem.shortDescription'
  ),
  renderer: (app: IApplication) => <DEMGeneration app={app} />,
  appScreenshotUrl: '',
  tags: [],
  acronym: clientTranslate('datacosmos.applications.generate-dem.acronym', {
    defaultValue: 'DEM',
  }),
  permissionsResourceId: 'generate-dem',
};

const DEMGeneration = ({ app }: Props) => {
  const {
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
    switchToApplicationRuns,
  } = useApplicationCatalog();

  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);
  const [isSubmittingWorkflow, setIsSubmittingWorkflow] =
    useState<boolean>(false);

  const [cursor, setCursor] = useState<number>(0);

  const { translate } = useLocalisation();
  const { currentScenario } = useProjects();
  const { user } = useAuth();

  const getDemReferencePath = () =>
    `/${currentScenario?.id ?? ''}/DEM-input/${user?.id ?? uuid()}.tiff`;
  const setDemReferenceError = () => {
    setInput(
      'demReference',
      app.values.demReference.value,
      true,
      translate(
        'datacosmos.applications.generate-dem.inputs.demReference.error'
      )
    );
  };
  const { mutate: uploadDEMReferenceMutation, isPending } = useMutation({
    mutationFn: ({ file }: { file: File }) =>
      putStorageFile({ body: file, params: { path: getDemReferencePath() } }),
    onSuccess: (response) => {
      if (response.success) {
        setValue('demReference', hosts.storage.v0 + getDemReferencePath());
      } else {
        setDemReferenceError();
      }
    },
    onError: () => {
      setDemReferenceError();
    },
  });

  const setIdValues = (img: SingleBandSTACLayer) => {
    const key = cursor === 0 ? 'leftImage' : 'rightImage';
    const isValidImage = isGeoreferencedWithGCP(img.item as StacItem);
    setInput(
      key,
      img.item.id,
      !isValidImage,
      !isValidImage
        ? translate(
            'datacosmos.applications.generate-dem.inputs.leftImage.error'
          )
        : ''
    );
    setCursor(cursor === 0 ? 1 : 0);
    return;
  };

  useClickedStacItem({
    outlineClickedItem: true,
    onImageClick: (img) => {
      setIdValues(img);
    },
  });

  const setInput = useCallback(
    (
      key: string,
      value: IAPIAppValue['value'],
      isError: boolean,
      message?: string
    ) => {
      setInputData(app.name, {
        ...app.values,
        [key]: { value, isError, message: message ?? '' },
      });
    },
    [app.name, app.values, setInputData]
  );

  const setValue = (key: string, value: IAPIAppValue['value']) => {
    setInput(key, value, false, '');
  };

  const resetInputValues = useCallback(() => {
    setInputData(app.name, {
      leftImage: { value: null, isError: false, message: '' },
      rightImage: { value: null, isError: false, message: '' },
      stereoAlgorithm: { value: null, isError: false, message: '' },
      subpixelMode: { value: null, isError: false, message: '' },
      demReference: { value: null, isError: false, message: '' },
    });
  }, [app.name, setInputData]);

  const inputs = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div className="flex flex-col gap-1">
          <span>
            {translate(
              'datacosmos.applications.generate-dem.inputs.leftImage.title'
            )}
          </span>
          <Input
            type="text"
            value={app.values.leftImage.value as string}
            placeholder={translate(
              'datacosmos.applications.generate-dem.inputs.leftImage.placeholder'
            )}
            readOnly
          />
          {app.values.leftImage?.isError && (
            <div>
              <small className="text-warning">
                {app.values.leftImage.message}
              </small>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-1">
          <span>
            {translate(
              'datacosmos.applications.generate-dem.inputs.rightImage.title'
            )}
          </span>
          <Input
            type="text"
            value={app.values.rightImage.value as string}
            readOnly
            placeholder={translate(
              'datacosmos.applications.generate-dem.inputs.rightImage.placeholder'
            )}
          />
          {app.values.rightImage?.isError && (
            <div>
              <small className="text-warning">
                {app.values.rightImage.message}
              </small>
            </div>
          )}
        </div>

        <Select
          fill
          label={translate(
            'datacosmos.applications.generate-dem.inputs.stereoAlgorithm.label'
          )}
          placeholder={translate(
            'datacosmos.applications.generate-dem.inputs.stereoAlgorithm.placeholder'
          )}
          onSelectionChange={(val) => {
            setValue('stereoAlgorithm', val);
            if (app.values.subpixelMode.value === '') {
              setValue(
                'subpixelMode',
                OPTIONS.find((o) => o.key === val)?.defaultSubpixelMode
              );
            } else {
              if (
                !OPTIONS.find((o) => o.key === val)?.subpixelModes?.includes(
                  app.values.subpixelMode.value as string
                )
              ) {
                setValue('subpixelMode', '');
              }
            }
          }}
          selectedKey={(app.values.stereoAlgorithm?.value as string) ?? ''}
        >
          {OPTIONS.map(({ key }) => (
            <ListBoxItem id={key} key={key}>
              {translate(
                `datacosmos.applications.generate-dem.inputs.stereoAlgorithm.${key}`
              )}
            </ListBoxItem>
          ))}
        </Select>

        <Select
          fill
          label={translate(
            'datacosmos.applications.generate-dem.inputs.subpixelMode.label'
          )}
          placeholder={translate(
            'datacosmos.applications.generate-dem.inputs.subpixelMode.placeholder'
          )}
          onSelectionChange={(val) => {
            setValue('subpixelMode', val);
          }}
          selectedKey={(app.values.subpixelMode?.value as string) ?? ''}
        >
          {OPTIONS.find(
            (x) => x.key === app.values.stereoAlgorithm?.value
          )?.subpixelModes.map((mode) => (
            <ListBoxItem id={mode} key={mode}>
              {mode}
            </ListBoxItem>
          ))}
        </Select>

        <label>
          {translate(
            'datacosmos.applications.generate-dem.inputs.demReference.label'
          )}
          <InputFileButton
            id="dem-reference-upload"
            accept="image/tiff,.tif,.geotiff"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const file = e.target.files?.[0];
              if (file && currentScenario && user) {
                uploadDEMReferenceMutation({ file });
              }
            }}
            text={translate('datacosmos.buttons.upload')}
          />
        </label>
        {isPending && (
          <div>
            <small className="flex gap-1">
              {translate(
                'datacosmos.applications.generate-dem.inputs.demReference.uploading'
              )}
              <Spinner size="1rem" />
            </small>
          </div>
        )}
        {Boolean(app.values.demReference?.value) && (
          <div>
            <small className="flex gap-1">
              {translate(
                'datacosmos.applications.generate-dem.inputs.demReference.uploaded'
              )}
              <Icon icon="tick" className="text-success" />
            </small>
          </div>
        )}
        {app.values.demReference?.isError && (
          <div>
            <small className="text-warning">
              {app.values.demReference.message}
            </small>
          </div>
        )}
      </div>
    );
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        toggleAppInstall={toggleAppInstall}
        isInstalled={getInstalledStatus(app)}
        handleSubmit={async () => {
          if (!currentScenario) {
            return;
          }
          setIsSubmittingWorkflow(true);
          const { success } = await submitWorkflow({
            body: {
              name: 'generate-dem',
              inputs: {
                'left-item-id': app.values.leftImage.value as string,
                'right-item-id': app.values.rightImage.value as string,
                'stereo-algorithm': app.values.stereoAlgorithm.value as string,
                'subpixel-mode': app.values.subpixelMode.value as string,
                'dem-reference': app.values.demReference.value as string,
              },
              labels: {
                'app.opencosmos.com/project_id': currentScenario?.id,
              },
            },
          });
          setIsSubmittingWorkflow(false);
          if (success && app.isTraceableWorkflow) {
            resetInputValues();
            switchToApplicationRuns();
          }
        }}
        submitButtonLabel={translate(
          'datacosmos.applications.global.buttons.submit'
        )}
        loading={isSubmittingWorkflow}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      isInstalled={getInstalledStatus(app)}
      setIsAppOpened={setIsAppOpened}
      setSelectedInstalledApp={setSelectedInstalledApp}
      toggleAppInstall={toggleAppInstall}
    />
  );
};

export default DEMGeneration;
