import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import focus from './focus';
import user from './user';
import permission from './permission';

const rootReducer = combineReducers({
  focus,
  user,
  permission,
  form: formReducer,
});
export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
