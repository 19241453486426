import { useLocation } from 'react-router';

export type PossibleRoute = 'data';

export type PageTitleOptions = {
  /**
   * Routes to ignore when setting the page title
   */
  ignoredRoutes?: PossibleRoute[];
};

const DEFAULT_TITLE = 'Conida';

/**
 * usePageTitle hook sets the page title based on the current application route.
 * It also allows to ignore certain routes from being set as page title.
 *
 * This hook is intended to be used in the root of the application, for example in the App.tsx file,
 * and it takes effect on every route change.
 *
 * @params options: PageTitleOptions - options to configure the hook
 */
export const usePageTitle = (options?: PageTitleOptions) => {
  const location = useLocation();

  const setPageTitle = (title: string) => {
    document.title = title;
  };

  if (
    options?.ignoredRoutes?.includes(
      location.pathname.split('/')[1] as PossibleRoute
    )
  ) {
    setPageTitle(DEFAULT_TITLE);
    return;
  }

  const formatDataSpecificPathnameAsTitle = (pathNameArray: string[]) => {
    if (pathNameArray.length <= 1) {
      return `CONIDA`;
    }

    return `CONIDA ${
      pathNameArray[pathNameArray.length - 1].charAt(0).toUpperCase() +
      pathNameArray[pathNameArray.length - 1].slice(1)
    }`;
  };

  const formatPathnameAsTitle = () => {
    const pathname = location.pathname.split('/').filter((p) => p !== '');

    if (pathname[0] === 'data') {
      return formatDataSpecificPathnameAsTitle(pathname);
    }

    return DEFAULT_TITLE;
  };

  setPageTitle(formatPathnameAsTitle());
};
