import React, { useCallback, useState } from 'react';
import conidaLogo from 'public/images/datacosmos/conida-logo.png';
import type { IAPIAppValue, IApplication } from 'datacosmos/types/applications';
import { btoaSafe } from 'utils/common/btoaSafe';
import { clientTranslate, useLocalisation } from 'utils/hooks/useLocalisation';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import OpenedAppCard from '../SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from '../SubscriptionApps/Common/UnopenedAppCard';
import { submitWorkflow } from '_api/pdgs/service';
import { Icon, Input } from 'opencosmos-ui';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import InputFileButton from 'components/buttons/InputFileButton';

type Props = {
  app: IApplication;
};

type JSONFile = {
  files: string[];
  metadata: object;
};

const fileIsValid = (json: JSONFile) => {
  return json.files && json.metadata;
};

export const PlanetIngestionApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name +
          JSON.stringify(this.provider) +
          this.description +
          this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: clientTranslate('datacosmos.applications.planetIngestion.title'),
  description: clientTranslate(
    'datacosmos.applications.planetIngestion.description'
  ),
  inputs: [
    {
      field: 'planet-foldername',
      example: '',
    },
    {
      field: 'planet-json',
      example: '',
    },
    {
      field: 'planet-json-filename',
      example: '',
    },
  ],
  values: {
    'planet-foldername': { value: '', isError: false, message: '' },
    'planet-json': { value: '', isError: false, message: '' },
    'planet-json-filename': { value: '', isError: false, message: '' },
  },
  provider: {
    id: 1,
    // If the name is blank, the logo will be full width.
    name: '',
    description:
      'Comisión Nacional de Investigación y Desarrollo Aeroespacial del Perú',
    url: 'https://www.gob.pe/conida',
    icon_url: conidaLogo,
  },
  shortDescription: clientTranslate(
    'datacosmos.applications.planetIngestion.shortDescription'
  ),
  renderer: (app: IApplication) => <PlanetIngestion app={app} />,
  appScreenshotUrl: '',
  tags: [],
  isTraceableWorkflow: true,
  acronym: 'PLA',
  permissionsResourceId: 'planet-basemap',
};

const PlanetIngestion = ({ app }: Props) => {
  const {
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
    switchToApplicationRuns,
  } = useApplicationCatalog();

  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);
  const [isSubmittingWorkflow, setIsSubmittingWorkflow] =
    useState<boolean>(false);

  const { translate } = useLocalisation();

  const { currentScenario } = useProjects();

  const setValue = useCallback(
    (key: string, value: IAPIAppValue['value']) => {
      setInputData(app.name, {
        ...app.values,
        [key]: { value, isError: false, message: '' },
      });
    },
    [app.name, app.values, setInputData]
  );

  const setError = useCallback(
    (key: string, message: string) => {
      setInputData(app.name, {
        ...app.values,
        [key]: { value: '', isError: true, message },
      });
    },
    [app.name, app.values, setInputData]
  );

  const resetInputValues = useCallback(() => {
    setValue('planet-foldername', '');
    setValue('planet-json', '');
    setValue('planet-json-filename', '');
  }, [setValue]);

  const inputs = useCallback(() => {
    return (
      <div>
        <div className="flex flex-col gap-4 mt-4 mb-4">
          <Input
            type="text"
            value={app.values['planet-foldername']?.value as string}
            onChange={(e) => {
              setValue('planet-foldername', e.target.value);
            }}
            label={{
              position: 'top',
              text: translate(
                'datacosmos.applications.planetIngestion.inputs.zipFile'
              ),
              wrapLabelText: true,
            }}
          />

          {app.values['planet-foldername']?.isError && (
            <div>
              <small className="text-warning">
                {app.values['planet-foldername'].message}
              </small>
            </div>
          )}

          <label className="block text-sm font-medium text-gray-700">
            {translate(
              'datacosmos.applications.planetIngestion.inputs.jsonFile'
            )}
            <InputFileButton
              id="planet-ingestion-upload"
              accept=".json"
              onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                const file = e.target.files?.[0];
                if (file) {
                  const text = await file.text();
                  try {
                    const json = JSON.parse(text) as JSONFile;
                    if (fileIsValid(json)) {
                      setValue('planet-json', json);
                      setValue('planet-json-filename', file.name);
                    } else {
                      setError(
                        'planet-json',
                        translate(
                          'datacosmos.applications.planetIngestion.errors.invalidJSON'
                        )
                      );
                      setError('planet-json-filename', '');
                    }
                  } catch (error) {
                    setError(
                      'planet-json',
                      translate(
                        'datacosmos.applications.planetIngestion.errors.invalidJSON'
                      )
                    );
                    setError('planet-json-filename', '');
                  }
                }
              }}
              text={translate('datacosmos.buttons.upload')}
            />
          </label>
          {app.values['planet-json']?.isError && (
            <div>
              <small className="text-warning">
                {app.values['planet-json'].message}
              </small>
            </div>
          )}
          {app.values['planet-json-filename']?.value ? (
            <div>
              <span className="flex gap-1">
                {app.values['planet-json-filename'].value as string}
                <Icon icon="tick" className="text-success" />
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  }, [app.values, setError, setValue, translate]);

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        toggleAppInstall={toggleAppInstall}
        isInstalled={getInstalledStatus(app)}
        handleSubmit={async () => {
          if (!currentScenario) {
            return;
          }
          setIsSubmittingWorkflow(true);
          const { success } = await submitWorkflow({
            body: {
              name: 'planet-basemap',
              inputs: {
                'product-filename': app.values['planet-foldername']
                  ?.value as string,
                'json-file': JSON.stringify(app.values['planet-json']?.value),
              },
              labels: {
                'app.opencosmos.com/project_id': currentScenario?.id,
              },
            },
          });
          setIsSubmittingWorkflow(false);

          if (success && app.isTraceableWorkflow) {
            resetInputValues();
            switchToApplicationRuns();
          }
        }}
        submitButtonLabel={translate(
          'datacosmos.applications.global.buttons.submit'
        )}
        loading={isSubmittingWorkflow}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      isInstalled={getInstalledStatus(app)}
      setIsAppOpened={setIsAppOpened}
      setSelectedInstalledApp={setSelectedInstalledApp}
      toggleAppInstall={toggleAppInstall}
    />
  );
};

export default PlanetIngestion;
