import { apiFetchHandler } from '_api/clientFetch';
import type { Customer, Order, Price, PaymentMethod } from './types';
import { hosts } from '_api/hosts';
import { clientTranslate } from 'utils/hooks/useLocalisation';
import { ORDER_SERVICE_ENDPOINT } from 'env';
import { toaster } from 'toaster';

export type GetImagePriceParams = {
  collection: string;
  itemId: string;
};

export const getImagePrice = apiFetchHandler<Price, GetImagePriceParams>({
  host: hosts.data.v0,
  endpoint: ({ collection, itemId }) =>
    `/${ORDER_SERVICE_ENDPOINT}/price?collection=${collection}&item=${itemId}`,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.price.cannotGet'
  ),
  method: 'GET',
  errorDescription: (e) => e.join('; '),
});

export const getOrdersList = apiFetchHandler<Order[]>({
  host: hosts.data.v0,
  endpoint: `/${ORDER_SERVICE_ENDPOINT}/orders`,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.orderList.cannotGet'
  ),
  method: 'GET',
  errorDescription: (e) => e.join('; '),
});

export type GetCheckoutResponse = {
  checkout_url: string;
};
export type GetCheckoutParams = {
  orderId: string;
};
export const getOrderCheckoutURL = apiFetchHandler<
  GetCheckoutResponse,
  GetCheckoutParams
>({
  host: hosts.data.v0,
  endpoint: ({ orderId }) =>
    `/${ORDER_SERVICE_ENDPOINT}/orders/${orderId}/checkout`,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.checkout.cannotGetURL'
  ),
  method: 'GET',
  errorDescription: (e) => e.join('; '),
});

export type GetCusomerByIdParams = {
  id: string;
};

export const getCustomerById = apiFetchHandler<Customer, GetCusomerByIdParams>({
  endpoint: ({ id }) => `/${ORDER_SERVICE_ENDPOINT}/customer/${id}`,
  host: hosts.data.v0,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.customers.cannotGet'
  ),
  method: 'GET',
  errorDescription: (e) => e.join('; '),
});

export type CreateOrderItemBody = {
  collection: string;
  item: string;
  level: string;
};

export type CreateOrderBody = {
  type: string;
  data: {
    order_line_items: CreateOrderItemBody[];
    payment_method: PaymentMethod;
  };
  organisation: number;
  external_payment_id?: string;
};

export const createOrder = apiFetchHandler<Order, {}, CreateOrderBody>({
  endpoint: `/${ORDER_SERVICE_ENDPOINT}/orders`,
  method: 'POST',
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.order.cannotPost'
  ),
  host: hosts.data.v0,
  onSuccess: () => {
    toaster.show({
      message: clientTranslate(
        'datacosmos.fetchSuccesses.orders.successfullyCreated'
      ),
      intent: 'success',
    });
  },
  errorDescription: (e) => e.join('; '),
});

export type CreatePreferencesParams = {
  orderId: string;
};

export type CreatePreferencesRes = {
  preference_id: string;
};

export const createPreferences = apiFetchHandler<
  CreatePreferencesRes,
  CreatePreferencesParams
>({
  endpoint: ({ orderId }) =>
    `/${ORDER_SERVICE_ENDPOINT}/orders/${orderId}/mercado-pago`,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.order.cannotPostPreferences'
  ),
  host: hosts.data.v0,
  method: 'POST',
  errorDescription: (e) => e.join('; '),
});

export type UpdateOrderParams = CreatePreferencesParams;
export type UpdateOrderBody = Partial<Order>;

export const updateOrder = apiFetchHandler<
  undefined,
  CreatePreferencesParams,
  UpdateOrderBody
>({
  endpoint: ({ orderId }) => `/${ORDER_SERVICE_ENDPOINT}/orders/${orderId}`,
  method: 'PATCH',
  host: hosts.data.v0,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.order.cannotPatch'
  ),
  errorDescription: (e) => e.join('; '),
});

export type GetBillingPortalResponse = {
  portal_link: string;
};

export type GetBillingPortalParams = {
  organisationId: string;
};

export const getBillingPortalURL = apiFetchHandler<
  GetBillingPortalResponse,
  GetBillingPortalParams
>({
  host: hosts.data.v0,
  endpoint: ({ organisationId }) =>
    `/${ORDER_SERVICE_ENDPOINT}/org_customer/customer_portal_link/${organisationId}`,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.checkout.cannotGetURL'
  ),
  method: 'GET',
  errorDescription: (e) => e.join('; '),
});

type uploadOrderPaymentImageBody = FormData;

export const postOrderPaymentImage = apiFetchHandler<
  undefined,
  CreatePreferencesParams,
  uploadOrderPaymentImageBody
>({
  endpoint: ({ orderId }) =>
    `/${ORDER_SERVICE_ENDPOINT}/orders/${orderId}/payment-image`,
  method: 'POST',
  contentType: 'multipart/form-data',
  host: hosts.data.v0,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.order.cannotPatch'
  ),
  errorDescription: (e) => e.join('; '),
});

type OrderPaymentImageResponse = Blob;

export const getOrderPaymentImage = apiFetchHandler<
  OrderPaymentImageResponse,
  CreatePreferencesParams
>({
  endpoint: ({ orderId }) =>
    `/${ORDER_SERVICE_ENDPOINT}/orders/${orderId}/payment-image`,
  method: 'GET',
  host: hosts.data.v0,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.order.cannotGetPaymentImage'
  ),
  errorDescription: (e) => e.join('; '),
  onError: ({ state }) => {
    if (state.status === 404) {
      // This indicates no payment image uploaded. No toaster for this case
      return true;
    }
    return false;
  },
});