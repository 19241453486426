import {
  getCustomerById,
  getOrdersList,
  updateOrder,
  getOrderPaymentImage,
  postOrderPaymentImage,
} from '_api/orders/service';
import type { Order } from '_api/orders/types';
import { useQuery } from '_api/useQuery';
import { DATE_FORMAT } from 'constants/datetime';
import { currencySymbols } from 'datacosmos/utils/stac';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { toaster } from 'toaster';
import { formatPrice } from 'utils/common/CommonUtils';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import { clientTranslate } from 'utils/hooks/useLocalisation';

export const useOrdersScreenData = () => {
  const [checkedOrder, setCheckedOrder] = useState<Order[]>([]);

  const [fromDateFilter, setFromDateFilter] = useState<Date>();
  const [toDateFilter, setToDateFilter] = useState<Date>();

  const [selectedOrder, setSelectedOrder] = useState<Order>();

  const { hasPermission: isAdmin } = useCheckPermissions({
    permissions: {
      actionScope: 'data:order:confirm',
      type: 'global',
    },
  });

  const {
    data: ordersList,
    loading: isOrdersLoading,
    refetch: refetchOrderList,
  } = useQuery(getOrdersList, {
    initialData: [],
  });

  const {
    data: selectedOrderPaymentImage,
    loading: isSelectedOrderPaymentImageLoading,
    refetch: getSelectedOrderPaymentImage,
  } = useQuery(getOrderPaymentImage, {
    initialData: undefined,
    skip: selectedOrder === undefined || !selectedOrder.external_payment_id,
    params: {
      orderId: selectedOrder?.id ?? '',
    },
  });

  const { data: customer, loading: isCustomerLoading } = useQuery(
    getCustomerById,
    {
      initialData: undefined,
      skip: selectedOrder === undefined,
      params: {
        id: selectedOrder?.customer_id ?? '',
      },
    }
  );

  const filteredOrders = useMemo(() => {
    return ordersList
      ?.filter((order) => {
        const orderDate = moment(order.created_at);
        const isAfter = fromDateFilter
          ? orderDate.isSameOrAfter(fromDateFilter, 'day')
          : true;
        const isBefore = toDateFilter
          ? orderDate.isSameOrBefore(toDateFilter, 'day')
          : true;
        return isAfter && isBefore;
      })
      .sort((a, b) => {
        return moment(b.created_at).diff(moment(a.created_at));
      });
  }, [fromDateFilter, ordersList, toDateFilter]);

  const ordersByDate = useMemo(() => {
    return filteredOrders?.reduce((acc, order) => {
      const date = moment(order.created_at).format(DATE_FORMAT);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(order);
      return acc;
    }, {} as Record<string, Order[]>);
  }, [filteredOrders]);

  const toggleOrderCheck = (order: Order) => {
    if (checkedOrder.includes(order)) {
      setCheckedOrder(checkedOrder.filter((o) => o !== order));
    } else {
      setCheckedOrder([...checkedOrder, order]);
    }
  };

  const toggleOrderSelect = (order: Order) => {
    if (selectedOrder === order) {
      setSelectedOrder(undefined);
    } else {
      setSelectedOrder(order);
    }
  };

  const isOrderSelected = (order: Order) => {
    return selectedOrder === order;
  };

  const isOrderChecked = (order: Order) => {
    return Boolean(checkedOrder.find((o) => o === order));
  };

  const markOrderAsPaid = async (order: Order) => {
    if (!isAdmin) {
      return;
    }

    await updateOrder({
      params: { orderId: order.id },
      body: { status: 'PAID' },
    });

    await refetchOrderList();
  };

  const markOrderAsCancelled = async (order: Order) => {
    await updateOrder({
      params: { orderId: order.id },
      body: { status: 'CANCELLED' },
    });

    await refetchOrderList();
  };

  const uploadOrderPaymentImage = async (order: Order, image: FormData) => {
    const { success } = await postOrderPaymentImage({
      params: { orderId: order.id },
      body: image,
    });

    if (success) {
      await getSelectedOrderPaymentImage();
      toaster.show({
        message: clientTranslate(
          'datacosmos.fetchSuccesses.orders.imageUploaded'
        ),
        intent: 'success',
      });
    }
  };

  const isImageUploaded = async (orderToCheck: Order) => {
    if (
      orderToCheck.status === 'UNPAID' &&
      orderToCheck.payment_method === 'BANK_TRANSFER'
    ) {
      const { data } = await getOrderPaymentImage({
        params: { orderId: orderToCheck.id },
      });
      return Boolean(data);
    }
    return false;
  };

  const getOrderTotalCost = (orderToCalculate: Order) => {
    if (!orderToCalculate) {
      return;
    }

    const total = orderToCalculate?.order_line_items.reduce(
      (itemTotal, item) => {
        return itemTotal + parseFloat(item.price.final);
      },
      0
    );

    const currency =
      orderToCalculate?.order_line_items.length > 0
        ? orderToCalculate?.order_line_items[0].price.currency
        : 'GBP';
    const currencySymbol = currencySymbols[currency] ?? currency;
    return `${currencySymbol} ${formatPrice(total)}`;
  };

  return {
    ordersByDate,
    isOrdersLoading,
    toggleOrderCheck,
    checkedOrder,
    fromDateFilter,
    toDateFilter,
    setFromDateFilter,
    setToDateFilter,
    toggleOrderSelect,
    isOrderSelected,
    selectedOrder,
    customer,
    isCustomerLoading,
    isAdmin,
    markOrderAsPaid,
    markOrderAsCancelled,
    getOrderTotalCost,
    isOrderChecked,
    uploadOrderPaymentImage,
    selectedOrderPaymentImage,
    getSelectedOrderPaymentImage,
    isSelectedOrderPaymentImageLoading,
    isImageUploaded,
  };
};
