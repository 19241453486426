import { clientTranslate } from 'utils/hooks/useLocalisation';

export const hasImager = (value: unknown) => {
  type Value =
    | { metadata?: { parameters?: { imager?: { name?: string } } } }
    | undefined;
  return Boolean((value as Value)?.metadata?.parameters?.imager?.name);
};

export const getPopupContentBase = (name: string, description: string) => {
  return `${name}, ${description}\n ${clientTranslate(
    'datacosmos.tooltips.layers.rightClick'
  )}`;
};

export const getPopupContentWithArea = (
  name: string,
  area: string | number
) => {
  return `<div>
    <div>${
      name ?? clientTranslate('datacosmos.tooltips.layers.rightClick')
    }</div>
    <div>${area + ' km²'}</div>
    <div>${clientTranslate('datacosmos.tooltips.layers.rightClick')}</div>
    </div>`;
};
