import { hosts } from '_api/hosts';
import { apiFetchHandler } from '_api/clientFetch';
import { toaster } from 'toaster';
import { clientTranslate } from 'utils/hooks/useLocalisation';

export type ResubmitWorkflowBody = {
  name: string;
  labels?: Record<string, string>;
  inputs: Record<string, string | number>;
};

export type ResubmitWorkflowResponse = {
  name: string;
};

export const submitWorkflow = apiFetchHandler<
  ResubmitWorkflowResponse,
  {},
  ResubmitWorkflowBody
>({
  host: hosts.data.v0,
  endpoint: '/applications/workflow/submit',
  errorMessage: clientTranslate(
    'datacosmos.applications.global.workflows.errors.post'
  ),
  errorDescription: (err) => err.join('; '),
  method: 'POST',
  onSuccess: (data) => {
    if (data?.name) {
      toaster.show({
        message: clientTranslate(
          'datacosmos.applications.global.workflows.success'
        ),
        intent: 'success',
      });
    }
  },
});
