import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import { setUserInformation } from './action';
import type { AppState } from '../../pages/shared/state/reducers/rootReducer';
import type { IUserInformation } from '../../constants/user/actionTypes';

export const setUserThunks =
  (
    userInformation: IUserInformation
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(setUserInformation(userInformation));
  };
