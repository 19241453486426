import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import moment from 'moment';
import React from 'react';

type Props = {
  /**
   * The minimum date that can be selected
   */
  minDate: Date;
  /**
   * The maximum date that can be selected
   */
  maxDate: Date;
  /**
   * The currently selected date
   */
  selectedDate: Date;

  /**
   * Whether the slider is shown or not
   */
  isShown: boolean;
  /**
   * The function that is called when the selected date changes
   */
  onChange?: ((value: number[]) => void) | undefined;
  marks?: number[];
};

/**
 * TimeSeriesSlider is a slider that can be used to move through the time series.
 */
const TimeSeriesSlider = ({
  minDate,
  maxDate,
  selectedDate,
  onChange,
  isShown,
  marks,
}: Props) => {
  if (!isShown) {
    return null;
  }
  return (
    <div>
      <RangeSlider
        className="hide-geopdf"
        numberOfHandles={1}
        step={1}
        minValue={moment(minDate).unix()}
        maxValue={moment(maxDate).unix()}
        value={[moment(selectedDate).unix()]}
        marks={marks}
        showValuesAboveHandles={true}
        handleLabelFormatter={(value) =>
          moment.unix(value).format('YYYY-MM-DD HH:mm:ss')
        }
        onChange={onChange}
        showScale={true}
        scaleLabelFormatter={(value) => moment.unix(value).format('YYYY-MM-DD')}
      />
    </div>
  );
};

export default TimeSeriesSlider;
