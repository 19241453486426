import React from "react";
import Topbar from "../Topbar/Topbar";
import Sidebar from "../Sidebar/Sidebar";
import { GroupedNavItems } from "../Sidebar/types";

type Props = {
  children?: React.ReactNode;
  selectedNavigationItemKey?: string;
  navigationItems: {
    items: GroupedNavItems;
    footerItems: GroupedNavItems;
  };
  header: {
    left?: React.ReactNode;
    center?: React.ReactNode;
    right?: React.ReactNode;
  };
  startCollapsed?: boolean;
  onToggleCollapse?: (isCollapsed: boolean) => void;
};

const OpenappNav = ({
  navigationItems,
  selectedNavigationItemKey,
  children,
  startCollapsed,
  onToggleCollapse,
  ...props
}: Props) => {
  const [isCollapsed, setIsCollapsed] = React.useState(startCollapsed ?? false);
  return (
    <div className="h-full dark:text-item-dark-contrast">
      <Topbar
        onActionBtnClick={() => {
          setIsCollapsed(!isCollapsed);
          onToggleCollapse && onToggleCollapse(!isCollapsed);
        }}
        {...props.header}
      />
      <div className="flex bg-surface dark:bg-surface-dark">
        <Sidebar
          navItems={navigationItems.items}
          navFooterItems={navigationItems.footerItems}
          isCollapsed={isCollapsed}
          selectedItemKey={selectedNavigationItemKey}
        />
        <div
          className="w-full h-full overflow-auto"
          style={{
            height: "calc(100vh - 50px)",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default OpenappNav;
