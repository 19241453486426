import _ from 'lodash';
import type { BBox } from 'geojson';
import config from 'datacosmos/config';
import { LOGO } from 'env';

const CENTIMETERS_PER_METER = 100;

export default {
  /**
   * Converts a string to a coordinates array.
   * Assumes a string in the following format:
   * -78.30681944444444,37.79557777777777
   *
   * @param  string
   *
   * @return Array with coordinates [lng, lat]
   */
  strToCoods: function (str: string): [number, number] | undefined {
    if (!str) {
      return undefined;
    }
    const regExp = new RegExp('^(-?[0-9]{1,2}.[0-9]+),(-?[0-9]{1,2}.[0-9]+)$');
    const res = str.match(regExp);

    const lat = parseFloat(res?.[1] ?? '0');
    const lon = parseFloat(res?.[2] ?? '0');

    if (!res || lat > 180 || lat < -180 || lon > 90 || lon < -90) {
      return undefined;
    }

    return [lat, lon];
  },

  getPolygonFeature: function (coords: [number, number]) {
    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: coords,
      },
    };
  },

  /**
   * Coverts the given GSD to meters or centimeters
   * @param  float gsd in meters
   * @return string
   */
  gsdToUnit: function (gsd: number | string) {
    let g = gsd;
    const isString = (s: number | string): s is string => {
      return _.isString(s);
    };
    if (isString(g)) {
      g = parseFloat(g);
    }
    // If it's less than 1m, convert to cm so it displays more nicely
    if (g < 1) {
      return Math.round(g * CENTIMETERS_PER_METER) + ' cm';
    }
    return Math.round(g) + ' m';
  },

  getMapViewString: function (
    lng: number | string,
    lat: number | string,
    zoom: number | string
  ) {
    return [lng, lat, zoom].join(',');
  },

  wrapPoint: function (pt: [number, number]) {
    pt = [pt[0], pt[1]];
    while (pt[0] < -180) {
      pt[0] += 360;
    }

    while (pt[0] >= 180) {
      pt[0] -= 360;
    }

    return pt;
  },

  // Hack. For some reason Leaflet doesn't get the full size of the
  // the map container and so doesn't fill in the map. Maybe there
  // is a transition effect that provides a smaller value at Leaflet's
  // init, so setting a timeout to re-measure makes the map fill up with
  // tiles.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delayedMapContainerResize: function (map: any) {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      map.invalidateSize();
    }, 1000);
  },

  // Canonical means to transition to complex URIs
  // You only need to pass the part that you want to change, the rest of
  // the URL will remain the same.
  // TODO: Return the path so that all anchor's use `href` rather than
  //       `onClick()`. So that you can see the URL when you hover over
  //       the link tag.
  pushURI: function () {
    // TODO: Possibly remove
    // parts = Object.assign(
    //   {
    //     map: props.params.map,
    //     square: props.params.square_id,
    //     user: props.params.user_id,
    //     image: props.params.image_id,
    //     latest: props.params.latest_id,
    //   },
    //   parts
    // );
    // let path = `/${parts.map}`;
    // // 'Latest' are the selection of recently uploaded images
    // let isLatest = parts.latest || (!parts.square && !parts.user);
    // if (isLatest && parts.image) {
    //   path += '/latest';
    // } else if (parts.square) {
    //   path += `/square/${parts.square}`;
    // } else if (parts.user) {
    //   path += `/user/${parts.user}`;
    // }
    // if (parts.image) {
    //   path += `/${parts.image}`;
    // }
    // if (props.history) props.history.replace(path, props.query);
  },
};
