import classNames from 'classnames';

export const DetailsItem = ({
  title,
  value,
  applyFlexStyle,
  ...aria
}: {
  title: string;
  value: string;
  applyFlexStyle?: boolean;
} & React.AriaAttributes) => {
  return (
    <div
      className={classNames(
        'dark:text-item-dark-contrast text-item-contrast pr-1 pl-1',
        {
          'flex gap-2': applyFlexStyle,
          'grid grid-cols-2': !applyFlexStyle,
        }
      )}
      {...aria}
    >
      <span className="break-all font-medium">{title}</span>
      <span className="break-all" style={{ textAlign: 'end' }}>
        {value}
      </span>
    </div>
  );
};

export const CompositeDetailsItem = ({
  title,
  element,
}: {
  title: string;
  element: React.ReactNode;
}) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '2fr 2fr',
      }}
      className="dark:text-item-dark-contrast text-item-contrast pr-1 pl-1"
    >
      <span>{title}</span>
      <div className="text-end">{element}</div>
    </div>
  );
};

export const JSONDetailsItem = ({
  title,
  value,
  ...aria
}: {
  title: string;
  value: { [key: string]: unknown } | { [key: string]: unknown }[];
} & React.AriaAttributes) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '2fr 2fr',
      }}
      className="dark:text-item-dark-contrast text-item-contrast"
      {...aria}
    >
      <span className="break-all font-medium">{title}</span>
      <div className="bg-item-dark-contrast dark:bg-item-contrast flex justify-center max-w-md">
        <pre className="overflow-y-auto w-full">
          {JSON.stringify(value, null, 2)}
        </pre>
      </div>
    </div>
  );
};
